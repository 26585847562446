import React from "react";
import { Link } from "react-router-dom";

const Termsandconditions = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div>
        <div className="page-title-section section section-padding-top-0">
          <div className="page-breadcrumb position-static">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Terms and Conditions</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="section position-relative align-cont mg-b-40">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div>
                  <h2 class="title">Terms of Service</h2>
                  <h2 class="title">
                    Cancellation and Refund Policy for our website
                    (rushividyabhyasam.org)
                  </h2>
                  <b>(a) Cancellation</b>
                  <p>
                    Cancellation is not possible once a course is purchased.
                  </p>
                  <b>(b) Refunds</b>
                  <p>
                    An amount is eligible for return if it meet the below
                    criteria:
                  </p>
                  <p>
                  - The course for which the amount was paid, was cancelled by rushividyabhyasam.org.
                 
                  <br/>- An incorrect amount was charged.</p>
                  <p>If a refund is approved, we will notify you of the same via e-mail. We will then initiate a refund to your credit card (or the original method of payment). The exact time taken to receive the credit will depend on your card issuer’s policies.For paid digital web courses, the refund will be initiated within 15 days of the rushividyabhyasam.org agreeing on the refund.</p>
                
                <b>(c) Queries / Complaints</b>
                 <p>All queries and complaints may be addressed to info@rushividyabhyasam.org.
We shall endeavour to address all queries and complaints within 3 (three) working days from the date of receipt of the query / complaint.</p>
                  <p>
                    Because COMPANY. (the “Company”, “we”, or “us”) gathers
                    information from the users (“users” or “you”) of its
                    “Rushividyabhyasam” website, we have prepared this Privacy
                    Policy to explain how we collect and use your information.
                    BY USING OUR THE Rushividyabhyasam SERVICE OR BY OTHERWISE
                    GIVING US YOUR INFORMATION, YOU AGREE TO THE TERMS OF THIS
                    PRIVACY POLICY. Rushividyabhyasam provides an online
                    platform whereby users can connect with vendors who offer
                    roadside assistance and tow services (hereafter,
                    “Providers”). The Privacy Policy covers all aspects of the
                    Rushividyabhyasam Service whether “online” (e.g., the
                    Rushividyabhyasam.com website, however accessed and/or used,
                    whether via personal computers, mobile devices or otherwise)
                    and “offline” (e.g., collection of data through mailings,
                    telephone, or in person interactions) activities owned,
                    operated, provided, or made available by Rushividyabhyasam.
                    This Privacy Policy also applies to your use of interactive
                    features or downloads that: (i) we own or control; (ii) are
                    available through the Rushividyabhyasam Service; or (iii)
                    interact with the Rushividyabhyasam Service and post or
                    incorporate this Privacy Policy. Please review the following
                    carefully so that you understand our privacy practices. If
                    you do not agree to this Privacy Policy, do not use any of
                    our Services or give us any of your information. In
                    addition, please review our Terms and Conditions, which may
                    apply to your use of the Rushividyabhyasam Service. This
                    Privacy Policy is incorporated by reference into the
                    applicable Terms and Conditions. If you have questions about
                    this Privacy Policy, please contact us at privacy@
                    Rushividyabhyasam.com.
                  </p>
                  <h2 class="title">
                    1. Rushividyabhyasam Collection of User Information
                  </h2>
                  <p>
                    <b>
                      (a) Collection of Personal Information Personal
                      Information.
                    </b>
                    <br />
                    As part of the Rushividyabhyasam Service, we will ask you to
                    provide us with certain categories of personal information,
                    including your name, e-mail address, mobile telephone
                    number, billing address, personal vehicle information, and
                    credit card account information (“Personal Information”). We
                    may collect this information through various forms and in
                    various places through the Rushividyabhyasam Service,
                    including account registration forms, contact us forms, or
                    when you otherwise interact with us. For example, when you
                    sign up to use the Services, you create a user profile.
                  </p>
                  <p>
                    The current required data fields are: <br />
                    Name <br />
                    Email <br />
                    Password
                    <br />
                    Mobile Phone Number <br />
                    Details for one or more automobiles <br />
                    Zip Code
                  </p>
                  <p>
                    Credit Card Number, expiration date & security code and or
                    information regarding your PayPal, Google Wallet or other
                    digital payment accounts You also have the option to upload
                    a photo when registering for the Rushividyabhyasam Service.
                    You may remove or update the photo at any time by logging
                    into your account. All of the foregoing (including your
                    photo) shall be hereafter referred to as “Personal
                    Information”). The Personal Information you share with us
                    (including your photo if submitted) shall be viewable by and
                    shared with Providers in connection with requests you submit
                    for roadside assistance services to verify your identity and
                    bill your credit card account for services you order.
                  </p>
                  <p>
                    Besides the Personal Information, we may also collect other
                    non-personally identifiable information relative to your use
                    of the Rushividyabhyasam Service as discussed below. Certain
                    features and aspect of the Rushividyabhyasam Service may
                    require different types and forms of Personal Information.
                    You can opt out of providing Personal Information by not
                    entering it or not using the Rushividyabhyasam Service,
                    although not providing certain requested Personal
                    Information may hinder your ability to use the
                    Rushividyabhyasam Service or features or aspect of the
                    Rushividyabhyasam Service.
                  </p>
                  <p>
                    <b>(b) Collection & Use of Non-Personal Information</b>{" "}
                    <br />
                    In addition to Personal Information, we and our third-party
                    service providers may use a variety of technologies that
                    automatically (or passively) collect certain information
                    whenever you visit or interact with the Rushividyabhyasam
                    Service(“Usage Information”). This Usage Information may
                    include the browser that you are using, the URL that
                    referred you to the Rushividyabhyasam Service, all of the
                    areas within Rushividyabhyasam Service- that you visit, and
                    the time of day, among other information. We may use Usage
                    Information for various reasons, including to enhance or
                    otherwise improve the Rushividyabhyasam Services. In
                    addition, we collect your IP address or other unique
                    identifier (“Device Identifier”) for your computer, mobile
                    or other device used to access the Services (any, a
                    “Device”). A Device Identifier is a number that is
                    automatically assigned to your Device used to access the
                    Rushividyabhyasam Service, and our computers identify your
                    Device by its Device Identifier. Usage Information may be
                    non-identifying or may be associated with you. Whenever we
                    associate Usage Information or a Device Identifier with your
                    Personal Information, we will treat it as Personal
                    Information.
                  </p>
                  <p>
                    In addition, tracking information is collected as you
                    navigate through our Services, including, but not limited to
                    geographic areas. If you access the Rushividyabhyasam
                    Service while traveling in a vehicle, your mobile phone will
                    send your GPS coordinates to our servers. Most GPS enabled
                    mobile devices can define one’s location to within 50 feet.
                    We collect this information for various purposes –
                    including, to determine the charge for the transportation
                    you requested via our Rushividyabhyasam Services, to provide
                    you with customer support, to send you promotions and
                    offers, to enhance Rushividyabhyasam Service, and for our
                    internal business purposes. We may also have features that
                    allow you to share this information with other people (such
                    as your family, friends or colleagues) if you choose.
                  </p>
                  <p>
                    A few of the methods that may be used to collect Usage
                    Information include, without limitation, the following (and
                    subsequent technology and methods hereafter developed):
                  </p>
                  <p>
                    Cookies. A cookie is a data file placed on a Device when it
                    is used to access the Rushividyabhyasam Service. A Flash
                    cookie is a data file placed on a Device via the Adobe Flash
                    plug-in that may be built-in to or downloaded by you to your
                    Device. Cookies and Flash Cookies may be used for many
                    purposes, including, without limitation, remembering you and
                    your preferences and tracking your visits to our web pages.
                    Cookies work by assigning a number to the user that has no
                    meaning outside of the assigning website.
                  </p>
                  <p>
                    If you do not want information to be collected through the
                    use of cookies, your browser allows you to deny or accept
                    the use of cookies. Cookies can be disabled or controlled by
                    setting a preference within your web browser or on your
                    Device. If you choose to disable cookies or Flash cookies on
                    your Device, some features of the Services may not function
                    properly or may not be able to customize the delivery of
                    information to you.
                  </p>
                  <p>
                    You should be aware that the Company cannot control the use
                    of cookies (or the resulting information) by third-parties,
                    and use of third party cookies is not covered by our Privacy
                    Policy.
                  </p>
                  <p>
                    Web Beacons. Small graphic images or other web programming
                    code called web beacons (also known as “1×1 GIFs” or “clear
                    GIFs”) may be included in our web and mobile pages and
                    messages. The web beacons are tiny graphics with a unique
                    identifier, similar in function to cookies, and are used to
                    track the online movements of Web users. In contrast to
                    cookies, which are stored in a user’s computer hard drive,
                    web beacons are embedded invisibly on Web pages and are
                    about the size of the period at the end of this sentence.
                    Web beacons or similar technologies help us better manage
                    content on the Rushividyabhyasam Service by informing us
                    what content is effective, count users of the
                    Rushividyabhyasam Service, monitor how users navigate the
                    Rushividyabhyasam Service, count how many e-mails that we
                    send were actually opened or to count how many particular
                    articles or links were actually viewed. We do not tie the
                    information gathered by web beacons to our customers’
                    personal information.
                  </p>
                  <p>
                    Embedded Scripts. An embedded script is programming code
                    that is designed to collect information about your
                    interactions with the Rushividyabhyasam Service, such as the
                    links you click on. The code is temporarily downloaded onto
                    your Device from our web server or a third party service
                    provider, is active only while you are connected to the
                    Rushividyabhyasam Service, and is deactivated or deleted
                    thereafter.
                  </p>
                  <p>
                    <b>(c) User Information from Third Parties</b>
                    <br /> We may, from time to time, supplement the information
                    we collect about you through the Rushividyabhyasam Service
                    with outside records from third parties in order to enhance
                    our ability to serve you, to tailor our content to you and
                    to offer you opportunities to purchase products or services
                    that we believe may be of interest to you. We may combine
                    the information we receive from those sources with
                    information we collect through the Rushividyabhyasam
                    Service. In those cases, we will apply this Privacy Policy
                    to any Personal Information received, unless we have
                    disclosed otherwise.
                  </p>
                  <p>
                    <b>(d) User-Submitted Information A Third Party</b>
                    <br />
                    If you choose to use our referral service to tell a friend
                    about the Rushividyabhyasam Service or other
                    Rushividyabhyasam activities (e.g. job openings), we will
                    ask you for your friend’s name and email address. We will
                    automatically send your friend a one-time email inviting him
                    or her to visit the Rushividyabhyasam Service. We store this
                    information for the sole purpose of sending this one-time
                    email and tracking the success of our referral program, and
                    do not use this information for any other marketing purpose
                    unless we obtain consent from that person or we explicitly
                    say otherwise. Please be aware that when you use any
                    referral feature offered through the Rushividyabhyasam
                    Service, your e-mail address may be included in the
                    communication sent to your friend. If you or your friend
                    would like us to remove this information from our database,
                    please contact us via e-mail at
                    support@Rushividyabhyasam.com.
                  </p>
                  <p>
                    <b>(f) Information Collected from Job Applicants</b>
                    <br />
                    If you wish to apply for a job with Rushividyabhyasam, we
                    will collect Personal Information such as your name, email
                    address, phone number and may collect additional information
                    such as resume. We use the information collected within this
                    area of the web site(s) to determine your qualifications for
                    the position in which you have applied and to contact you to
                    set up an interview.
                  </p>
                  <h2 class="title">2. Use of User Information</h2>
                  <p>
                    Our primary goal in collecting your Personal information or
                    Usage Information is to provide you with the
                    Rushividyabhyasam Service.
                  </p>
                  <p>
                    We may send you e-mails to provide the Rushividyabhyasam
                    Service to you, including to verify your username and
                    password, to respond to your inquiries, to provide the
                    services you request, and to manage your account. We will
                    communicate with you by email, telephone, or SMS or text
                    message based upon your expressed preference where
                    applicable.
                  </p>
                  <p>
                    By registering to the Rushividyabhyasam Service, you also
                    consent to receiving various announcements about the
                    Rushividyabhyasam and the Rushividyabhyasam Service, (1) to
                    provide you with information or services or process
                    transactions that you have requested or agreed to receive
                    including to send you electronic newsletters, or to provide
                    you with special offers or promotional materials on behalf
                    of us or third parties; (2) to process your registration
                    with the Rushividyabhyasam Services, including verifying
                    your information is active and valid; (3) to improve the
                    Services or our services, to customize your experience with
                    the Services, or to serve you specific content that is most
                    relevant to you; (4) to enable you to participate in a
                    variety of the Services’ features such as online or mobile
                    entry sweepstakes, contests or other promotions; (5) to
                    contact you with regard to your use of the Rushividyabhyasam
                    Services and, in our discretion, changes to the
                    Rushividyabhyasam Service and/or the Rushividyabhyasam
                    Service’s policies; (6) for internal business purposes; (7)
                    for inclusion in our data analytics; (8) to address
                    maintenance or other issues with the Rushividyabhyasam
                    Service and (9) for purposes disclosed at the time you
                    provide your information or as otherwise set forth in this
                    Privacy Policy. If you do not wish to receive them, you have
                    the option of deactivating your Rushividyabhyasam account.
                  </p>
                  <p>
                    Please note that information submitted to the Services via a
                    “contact us” or other similar function may not receive a
                    response.
                  </p>
                  <h2 class="title">
                    3. Sharing & Disclosure of User Information
                  </h2>
                  <p>
                    We may share non-personally identifiable information, such
                    as aggregated user statistics and log data, with third
                    parties for industry analysis, demographic profiling, to
                    deliver targeted advertising about other products or
                    services, or for other business purposes. We do not sell,
                    share, rent or trade the information we have collected about
                    you, including Personal Information, other than as disclosed
                    within this Privacy Policy or at the time you provide your
                    information. We do not share your Personal Information with
                    third parties for those third parties’ direct marketing
                    purposes unless you consent to such sharing at the time you
                    provide your Personal Information. We may share user
                    information of all types (including Personal Information) in
                    the following circumstances:
                  </p>
                  <p>
                    <b>
                      (a) You Request To Receive Information From Third Parties.
                    </b>
                    <br />
                    You may elect to have your Personal Information shared to to
                    receive information and/or marketing offers directly from
                    third parties. If you do agree to have your Personal
                    Information shared, your Personal Information will be
                    disclosed to such third parties and all information you
                    disclose will be subject to the privacy policy and practices
                    of such third parties. We are not responsible for the
                    privacy policies and practices of such third parties and,
                    therefore, you should review the privacy policies and
                    practices of such third parties prior to agreeing to receive
                    such information from them. If you later decide that you no
                    longer want to receive communication from a third party, you
                    will need to contact that third party directly.
                  </p>
                  <p>
                    <b>(b) Third Party Service Providers</b>
                    <br />
                    We use third party companies and individuals to facilitate
                    the Rushividyabhyasam Service, provide or perform certain
                    aspects of the Rushividyabhyasam Service on our behalf –
                    such as Providers and other third-parties to host the
                    Rushividyabhyasam Service, design and/or operate the
                    Rushividyabhyasam Service’s features, track the
                    Rushividyabhyasam Service’s analytics, process payments,
                    engage in anti-fraud and security measures, provide customer
                    support, provide geo-location information to our drivers,
                    enable us to send you special offers, host our job
                    application form, perform technical services (e.g., without
                    limitation, maintenance services, database management, web
                    analytics and improvement of the Rushividyabhyasam Service’s
                    features), or perform other administrative services. We may
                    provide these vendors with access to user information,
                    including Personal Information, this information sharing is
                    limited to only the information needed by the vendor to
                    carry out the services they are performing for you or for
                    us. Each of these vendors are obligated not to disclose or
                    use Personal Information for any other purpose. This
                    exception does not encompass our use of third party
                    analytics service providers who evaluate and provide us with
                    information about the use of the Rushividyabhyasam Service
                    and viewing of our content. However, such service providers
                    may set and access their own cookies, web beacons and
                    embedded scripts on your Device and they may otherwise
                    collect or have access to information about you, including
                    non-personally identifiable information.
                  </p>
                  <p>
                    We use a third party hosting provider who hosts our support
                    section of our website. Information collected within this
                    section of our web site is governed by our Privacy Policy.
                  </p>
                  <p>
                    <b>(c) Sweepstakes, Contests and Promotions.</b>
                    <br />
                    If you elect to participate in a sweepstakes, contests, and
                    other promotions (any, a “Promotion”) through the
                    Rushividyabhyasam Service, , you are agreeing to official
                    rules that govern that Promotion, which may contain specific
                    requirements of you, including, allowing the sponsor of the
                    Promotion to use your name, voice and/or likeness in
                    advertising or marketing associated with the Promotion. If
                    you choose to enter a Promotion, Personal Information may be
                    disclosed to third parties or the public in connection with
                    the administration of such Promotion, including, in
                    connection with winner selection, prize fulfillment, and as
                    required by law or permitted by the Promotion’s official
                    rules, such as on a winners list.
                  </p>
                  <p>
                    <b>(d) Legal Protection and Compliance</b>
                    <br />
                    We cooperate with government and law enforcement officials
                    and private parties to enforce and comply with the law.
                    Thus, we may access, use, preserve, transfer and disclose
                    your information (including Personal Information), including
                    disclosure to third parties such as government or law
                    enforcement officials or private parties as we reasonably
                    determine is necessary and appropriate: (i) to satisfy any
                    applicable law, regulation, subpoenas, governmental requests
                    or legal process; (ii) to protect and/or defend the Terms
                    and Conditions for the Rushividyabhyasam Service, including
                    investigation of potential violations thereof; (iii) to
                    protect the safety, rights, property or security of the
                    Company, the Rushividyabhyasam Service or any third party;
                    (iv) to protect the safety of the public for any reason; (v)
                    to detect, prevent or otherwise address fraud, security or
                    technical issues; an /or (vi) to prevent or stop activity we
                    may consider to be, or to pose a risk of being, an illegal,
                    unethical, or legally actionable activity. Further, we may
                    use IP address or other Device Identifiers, to identify
                    users, and may do so in cooperation with third parties such
                    as copyright owners, internet service providers, wireless
                    service providers and/or law enforcement agencies, including
                    disclosing such information to third parties, all in our
                    discretion. Such disclosures may be carried out without
                    notice to you subject to applicable law.
                  </p>
                  <p>
                    <b>(e) Business Transfer.</b>
                    <br />
                    We may share your information, including your Personal
                    Information and Usage Information with our parent,
                    subsidiaries and affiliates for internal reasons. We also
                    reserve the right to disclose and transfer all such
                    information: (i) to a subsequent owner, co-owner or operator
                    of the Rushividyabhyasam Service or applicable database; or
                    (ii) in connection with a corporate merger, consolidation,
                    bankruptcy, restructuring, the sale of substantially all of
                    our equity interests and/or assets or other corporate
                    change, including, during the course of any due diligence
                    process. To the maximum extent allowable by law, the Privacy
                    Policy will apply to the user information as transferred to
                    the successor entity. However, user information submitted
                    after a transfer to a successor entity may be subject to a
                    new privacy policy adopted by the successor entity.
                  </p>
                  <h2 class="title">
                    4. What is Online Behavioral Advertising and How Can I
                    Opt-Out?
                  </h2>
                  <p>
                    Targeted advertising (also known as Behavioral Advertising)
                    uses information collected on an individual’s web or mobile
                    browsing behavior such as the pages they have visited or the
                    searches they have made. This information is then used to
                    select which advertisements should be displayed to a
                    particular individual on websites other than our web
                    site(s). For example, if you have shown a preference for
                    nursing while visiting our web site(s), you may be served an
                    advertisement for nursing-related programs when you visit a
                    site other than our web site(s). The information collected
                    is only linked to an anonymous cookie ID (alphanumeric
                    number); it does not include any information that could be
                    linked back to a particular person, such as their name,
                    address or credit card number. The information used for
                    targeted advertising either comes from us or through third
                    party website publishers.
                  </p>
                  <p>
                    If you would like to opt out of targeted advertising from us
                    that occurs when visiting our third party advertising
                    publishers, please email marketing@ Rushividyabhyasam.com.
                    Please note that this will opt you out of targeted ads from
                    our Company and any other participating advertisers. If you
                    opt out, you may continue to receive online advertising from
                    us; however, these ads may not be as relevant to you.
                  </p>
                  <p>
                    In order for behavioral advertising opt-outs to work on your
                    Device, your browser must be set to accept cookies. If you
                    delete cookies, buy a new Device, access our Services from a
                    different device, login under a different screen name, or
                    change web browsers, you will need to opt-out again. If your
                    browser has scripting disabled, you do not need to opt out,
                    as online behavioral advertising technology does not work
                    when scripting is disabled. Please check your browser’s
                    security settings to validate whether scripting is active or
                    disabled.
                  </p>
                  <p>
                    Additionally, many network advertising programs allow you to
                    view and manage the interest categories they have compiled
                    from your online browsing activities. These interest
                    categories help determine the types of targeted
                    advertisements you may receive. The NAI Opt-Out Page
                    provides a tool that identifies its member companies that
                    have cookies on your browser and provides links to those
                    companies.
                  </p>
                  <h2 class="title">5. User- Posted Content</h2>
                  <p>
                    The Rushividyabhyasam Service may offer publicly accessible
                    blogs or community forums or other ways to permit you to
                    submit ideas, photographs, user profiles, writings, music,
                    video, audio recordings, computer graphics, pictures, data,
                    questions, comments, suggestions or other content, including
                    Personal Information (collectively, “User Content”). We or
                    others may reproduce, publish, distribute or otherwise use
                    User Content online or offline in any media or format
                    (currently existing or hereafter developed). Others may have
                    access to this User Content and may have the ability to
                    share it with third parties across the Internet. You should
                    be aware that any User Content you provide in these areas
                    may be read, collected, and use by others who access them.
                    Thus, please think carefully before deciding what
                    information you share, including Personal Information, in
                    connection with your User Content. Please note that we do
                    not control who will have access to the information that you
                    choose to make public, and cannot ensure that parties who
                    have access to such publicly available information will
                    respect your privacy or keep it secure. This Privacy Policy
                    does not apply to any information that you disclose
                    publicly, share with others or otherwise upload, whether
                    through the Rushividyabhyasam Service or otherwise. We are
                    not responsible for the accuracy, use or misuse of any
                    content or information that you disclose or receive through
                    the Rushividyabhyasam Service.
                  </p>
                  <p>
                    To request removal of your User Content from our blog or
                    community forum or similar features, contact us through
                    support@Rushividyabhyasam.com. In some cases, we may not be
                    able to remove your User content, in which case we will let
                    you know if we are unable to do so and why.
                  </p>
                  <h2 class="title">6. Third Party Content & Links</h2>
                  <p>
                    The Rushividyabhyasam Service may contain content that is
                    supplied by a third party, and those third parties may
                    collect web site usage information and your Device
                    Identifier when web pages from any online or mobile service
                    are connected to your browser. In addition, when you are
                    using the Rushividyabhyasam Service, you may be directed to
                    other sites or applications that are operated and controlled
                    by third parties that we do not control. We are not
                    responsible for the privacy practices employed by any of
                    these third parties. For example, if you click on a banner
                    advertisement, the click may take you away from one of our
                    websites onto a different web site. These other web sites
                    may send their own cookies to you, independently collect
                    data or solicit Personal Information and may or may not have
                    their own published privacy policies. We encourage you to
                    note when you leave the Rushividyabhyasam Service and to
                    read the privacy statements of all third party web sites or
                    applications before submitting any Personal Information to
                    third parties.
                  </p>
                  <h2 class="title">7. Social Media Features and Widgets</h2>
                  <p>
                    The Rushividyabhyasam Service may include social media
                    features, such as the Facebook Like button, and widgets such
                    as a “Share This” button, or interactive mini-programs that
                    run on our online and mobile platforms. These features may
                    collect your IP address, which page you are visiting on our
                    online or mobile platform, and may set a cookie to enable
                    the feature to function properly. Social media features and
                    widgets are either hosted by a third party or hosted
                    directly by Rushividyabhyasam. Your interactions with these
                    features and widgets are governed by the privacy policy of
                    the company providing them.
                  </p>
                  <h2 class="title">8. Maintenance of User Information</h2>
                  <p>
                    You are responsible for maintaining the accuracy of the
                    information you submit to us, such as your contact
                    information provided as part of account registration. If
                    your Personal Information changes, or if you no longer
                    desire the Rushividyabhyasam Service, you may correct,
                    delete inaccuracies, or amend information by making the
                    change on our member information page or by contacting us
                    through support@Rushividyabhyasam.com. We will make good
                    faith efforts to make requested changes in our then active
                    databases as soon as reasonably practicable.
                  </p>
                  <p>
                    You may also cancel or modify your communications that you
                    have elected to receive from the Services by following the
                    instructions contained within an e-mail or by logging into
                    your user account and changing your communication
                    preferences.
                  </p>
                  <p>
                    If you wish to cancel your account or request that we no
                    longer use your information to provide you services, contact
                    us through support@Rushividyabhyasam.com.
                  </p>
                  <p>
                    We will retain your Personal Information and Usage
                    Information (including geo-location) for as long as your
                    account with the Rushividyabhyasam Service is active and as
                    needed to provide you services. Even after your account is
                    terminated, we will retain your Personal Information and
                    Usage Information (including geo-location, trip history,
                    credit card information and transaction history) as needed
                    to comply with our legal and regulatory obligations, resolve
                    disputes, conclude any activities related to cancellation of
                    an account (such as addressing chargebacks from your credit
                    card companies), investigate or prevent fraud and other
                    inappropriate activity, to enforce our agreements, and for
                    other business reason. After a period of time, your data may
                    be anonymized and aggregated, and then may be held by us as
                    long as necessary for us to provide the Rushividyabhyasam
                    Service effectively, but our use of the anonymized data will
                    be solely for analytic purposes.
                  </p>
                  <h2 class="title">
                    9. Minors Prohibited from Using the Rushividyabhyasam
                    Service
                  </h2>
                  <p>
                    Rushividyabhyasam cares about the safety of children.
                    Because the Rushividyabhyasam Service is not directed toward
                    minors, no one under 18 (and certainly no children under 13)
                    are allowed to register with or use the Rushividyabhyasam
                    Service. We do not knowingly collect personal information
                    from anyone under the age of 18. If we are notified that we
                    have collected personal information from a person under 18,
                    we will delete that information immediately upon
                    notification. If you are a parent or guardian of a minor
                    under the age of eighteen (18) and believe he or she has
                    disclosed Personal Information to us, please contact us at
                    privacy@Rushividyabhyasam.com.
                  </p>
                  <h2 class="title">10. Data Security</h2>
                  <p>
                    We use standard, industry-wide, commercially reasonable
                    security practices such as encryption, firewalls and SSL
                    (Secure Socket Layers) for protecting your information –
                    such as any portions of your credit card number which we
                    retain (we do not ourselves retain your entire credit card
                    information) and geo-location information. Please note that
                    data that is transported over an open network, such as the
                    Internet or e-mail, may be accessible to anybody. We cannot
                    guarantee the confidentiality of any communication or
                    material transmitted via such open networks. When disclosing
                    any information via an open network, you should remain
                    mindful of the fact that it is potentially accessible to
                    others, and consequently, can be collected and used by
                    others without your consent. In particular, while individual
                    data packets are often encrypted, the names of the sender
                    and recipient are not. A third party may therefore be able
                    to trace an existing bank account or relationship or one
                    that is subsequently created. Even if both the sender and
                    recipient are located in the same country data may also be
                    transmitted via such networks to other countries regularly
                    and without controls, including to countries that do not
                    afford the same level of data protection as your country of
                    domicile. Password-protected areas of the Rushividyabhyasam
                    Service can be accessed only with a valid user name and
                    password. Each password owner is responsible for keeping the
                    password confidential and safe, as Rushividyabhyasam has no
                    control or responsibility for this type of User information.
                    If the password may have been stolen or might otherwise be
                    misused, it is the responsibility of the password owner to
                    notify Rushividyabhyasam immediately for further action.
                    Your data may be lost during transmission or may be accessed
                    by unauthorized parties. We do not accept any liability for
                    direct or indirect losses as regards the security of your
                    information or data during its transfer via Internet. Please
                    use other means of communication if you think this is
                    necessary or prudent for security reasons
                  </p>
                  <h2 class="title">
                    11. Rushividyabhyasam Right to Amend this Privacy Policy.
                  </h2>
                  <p>
                    From time to time, we may update this Privacy Policy to
                    reflect changes to our information practices. Any changes
                    will be effective immediately upon the posting of the
                    revised Privacy Policy. If we make any material changes, we
                    will notify you by email (sent to the e-mail address
                    specified in your account) or by means of a notice on the
                    Services prior to the change becoming effective. We
                    encourage you to periodically review this page for the
                    latest information on our privacy practices.
                  </p>
                  <h2 class="title">12. Users Outside of the United States.</h2>
                  <p>
                    If you are located anywhere outside of the United States,
                    please be aware that information we collect, including,
                    Personal Information, will be transferred to, processed and
                    stored in the United States. The data protection laws in the
                    United States may differ from those of the country in which
                    you are located, and your Personal Information may be
                    subject to access requests from governments, courts, or law
                    enforcement in the United States according to laws of the
                    United States. By using the Services or providing us with
                    any information, you consent to this transfer, processing
                    and storage of your information in the United States.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Termsandconditions;
