import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Course from "./Course";
import Home from "./Home";
import CourseDetails from "./CourseDetails";
import Header from "./Header";
import Footer from "./Footer";
import Signup from "./Signup";
import Login from "./Login";
import PaymentSucess from "./PaymentSucess";
import Contactus from "./Contactus";
import Aboutus from "./Aboutus";
import Events from "./Events";
import MyAccount from "./MyAccount";
import Forgotpassword from "./Forgotpassword";
import PrivacyPolicy from "./PrivacyPolicy";
import Termsandconditions from "./Termsandconditions";
import Launch from "./Launch";
import Privateroute from "../components/Privateroute";
import FirstBatchOfBhagavadGitaLearners from "./FirstBatchOfBhagavadGitaLearners";
import EventDetails from "./EventDetails";
import ListOfCourses from "./ListOfCourses";
import EventSlotBooking from "../components/EventSlotBooking";
export default function RouterPage() {
  // React.useEffect(() => {
  //   let l = localStorage.getItem("isUpdated");
  //   if (!l) {
  //     alert("Please update your profile");
  //     return <h1>update Profile</h1>;
  //   }
  //   //eslint-disable-next-line
  // }, []);
  return (
    <div>
      <Router>
        <Header />
        <Routes>
          <Route
            path="/"
            element={
              <Privateroute>
                <Home />
              </Privateroute>
            }
          ></Route>
          <Route
            path="/course"
            element={
              <Privateroute>
                <Course />
              </Privateroute>
            }
          ></Route>
          <Route path="/coursedetails/:id" element={<CourseDetails />}></Route>
          <Route path="/signup" element={<Signup />}></Route>
          <Route path="/login" element={<Login />}></Route>
          <Route path="/paymentsuccess" element={<PaymentSucess />}></Route>
          <Route
            path="/contactus"
            element={
              <Privateroute>
                <Contactus />
              </Privateroute>
            }
          ></Route>
          <Route
            path="/aboutus"
            element={
              <Privateroute>
                <Aboutus />
              </Privateroute>
            }
          ></Route>
          <Route
            path="/first-batch-of-bhagavad-gita-learners"
            element={
              <Privateroute>
                <FirstBatchOfBhagavadGitaLearners />
              </Privateroute>
            }
          ></Route>
          <Route path="/eventtickets" element={<EventSlotBooking />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route path="/eventDetails/:id" element={<EventDetails />}></Route>
          <Route path="/myaccount" element={<MyAccount />}></Route>
          <Route path="/forgotpassword" element={<Forgotpassword />}></Route>
          <Route path="/privacypolicy" element={<PrivacyPolicy />}></Route>
          <Route
            path="/termsandconditions"
            element={<Termsandconditions />}
          ></Route>
          <Route path="/launch" element={<Launch />}></Route>
          <Route path="/listofcourses" element={<ListOfCourses />}></Route>
        </Routes>

        <Footer />
      </Router>
    </div>
  );
}
