import React from "react";
import { Navigate } from "react-router-dom";
const Privateroute = (props) => {
  //   React.useEffect(() => {
  let l = localStorage.getItem("isUpdated");
  //     if (!l) {
  //       alert("Please update your profile");
  //       return <h1>update Profile</h1>;
  //     }
  //eslint-disable-next-line
  //   }, []);
  return (
    <div>
      {props.children}
      {l !== "true" && localStorage.getItem("uid") ? (
        <Navigate to="/myaccount"></Navigate>
      ) : null}
    </div>
  );
};
export default Privateroute;