import React, { useEffect, useState } from "react";
import Axios from "../utilities/Axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Validator } from "../utilities/validation";
const Contactus = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const initialFormState = {
    fullname: "",
    email: "",
    subject: "",
    message: "",
    course_type_id: "",
  };
  let validator = Validator();
  const [document, setDocument] = useState(initialFormState);
  const [errorname, setErrorname] = useState(false);
  const [errormail, setErrormail] = useState(false);
  const [dummy, setDummy] = useState(false);
  const [errorsubject, setErrorsubject] = useState(false);
  const [errCourseType, setErrorcourseType] = useState(false);
  const [onSelectc, setOnSelectc] = useState(false);

  const [, forceUpdate] = React.useState(false);
  const [courseNames, setCourseNames] = useState([]);
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDocument({ ...document, [name]: value });
  };
  const handleInputChanges = (event) => {
    const { name, value } = event.target;
    setOnSelectc(true);
    setDocument({ ...document, [name]: value });
  };
  const formsubmit = () => {
    // console.log(document, "contactuss");
    // e.preventDefault();
    //if (validator.current.allValid()) {
    // alert("from 16");
    var error = "no";
    if (document.fullname === "") {
      setErrorname(true);
      error = "yes";
    }
    if (document.email === "") {
      setErrormail(true);
      error = "yes";
    }
    if (document.subject === "") {
      setErrorsubject(true);
      error = "yes";
    }
    if (document.course_type_id === "") {
      setErrorcourseType(true);
      error = "yes";
    } else {
    }
    if (error === "no") {
      setDummy(true);
      callApi();
    }

    // } else
    //   forceUpdate(true);
    //   validator.current.showMessages();
    // }
  };
  useEffect(() => {
    console.log("resultsssssss");
    const fetchCourses = async () => {
      try {
        const response = await Axios.get(
          "course_type_admin/get_all_admin_course_types"
        );
        setCourseNames(response.data.data);
        console.log(response.data.data, "resultss");
      } catch (error) {
        console.error("resultssError fetching data:", error);
      }
    };
    fetchCourses();
  }, []);
  const callApi = () => {
    setOnSelectc(false);
    setErrorname(false);
    setErrormail(false);
    setErrorsubject(false);
    setErrorcourseType(false);

    Axios.post("user_details/contact_us", document)
      .then((res) => {
        // console.log(res, "res");
        if (res?.data?.status === 200) {
          toast("Mail sent Successfully", {
            position: "bottom-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
          });
          setDocument(initialFormState);
          setDummy(false);
        }
      })
      .catch((err) => {
        console.log(err);
        //   if (err?.data.status === 400) {
        //     alert(err.data.error);
        //   }
      });
  };
  return (
    <div>
      <div>
        {/* Page Title Section Start */}
        <div className="page-title-section section section-padding-top-0">
          <div className="page-breadcrumb position-static">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Contact us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Page Title Section End */}
        {/* Contact Information Section Start */}
        <div className="contact-information-section section section-padding-bottom">
          <div className="container">
            <div className="row margin-bottom-85">
              <div className="col-12">
                <div className="contact-title max-width-740">
                  <h2 className="title">
                    For more information about our courses, get in touch with
                    Rushividyabhyasam via contacts
                  </h2>
                </div>
              </div>
            </div>
            <div className="row row-30 row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
              <div className="col max-mb-30">
                {/* Single Contact Info Start */}
                <div className="contact-info">
                  <div className="icon">
                    <i className="fal fa-map-marker-alt" />
                  </div>
                  <div className="info">
                    <h4 className="title"> Address</h4>
                    <span className="info-text">
                      {" "}
                      Plot No: 1-9-46, HIG A-40, Dr A S Rao Nagar Hyderabad -
                      500 062, Telangana, India
                    </span>
                  </div>
                </div>
                {/* Single Contact Info End */}
              </div>
              <div className="col max-mb-30">
                {/* Single Contact Info Start */}
                <div className="contact-info">
                  <div className="icon">
                    <i className="fal fa-phone" />
                  </div>
                  <div className="info">
                    <h4 className="title"> Contact</h4>
                    <span className="info-text">
                      {" "}
                      Mobile: <strong>040-2713 2550</strong>
                      <br />
                      Mail:{" "}
                      <a href="mailto:info@rushividyabhyasam.org">
                        info@rushividyabhyasam.org
                      </a>
                    </span>
                  </div>
                </div>
                {/* Single Contact Info End */}
              </div>
              <div className="col max-mb-30">
                {/* Single Contact Info Start */}
                <div className="contact-info">
                  <div className="icon">
                    <i className="fal fa-clock" />
                  </div>
                  <div className="info">
                    <h4 className="title"> Hours of operation</h4>
                    <span className="info-text">
                      {" "}
                      Monday – Saturday : 10:00AM – 06:00PM <br /> Sunday :
                      Closed
                    </span>
                  </div>
                </div>
                {/* Single Contact Info End */}
              </div>
            </div>
          </div>
        </div>
        <div className="google-map-area section text-center">
          <div className="container">
            <div className="contact-map-area">
              <iframe
                className="contact-map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d237.8476101994314!2d78.55329430468939!3d17.48067711751302!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b7798008f25%3A0x2a02b2cca309f806!2sRushipeetham%20Charitable%20Trust!5e0!3m2!1sen!2sin!4v1639718711565!5m2!1sen!2sin"
                allowFullScreen
                aria-hidden="false"
                tabIndex={0}
              />
            </div>
          </div>
        </div>
        {/* Contact Information Section End */}
        <div className="contact-form-section section section-padding">
          <div className="container">
            <div className="row">
              <div className="offset-lg-2 col-lg-8">
                <div className="contact-title max-width-600">
                  <h2 className="title">
                    Fill out this form to contact us.
                  </h2>
                </div>
                <div className="contact-form">
                  <form id="contact-form11" action="#">
                    <div className="row max-mb-n30">
                      <div className="col-md-6 col-12 max-mb-30">
                        <input
                          type="text"
                          placeholder="Your Name *"
                          name="fullname"
                          value={document.fullname}
                          onChange={handleInputChange}
                        />
                        {/* {validator.current.message(
                          "fullname",
                          document.fullname,
                          "required|fullname"
                        )} */}
                        <span style={{ color: "red" }}>
                          {errorname ? "Fullname is required" : ""}
                        </span>
                      </div>
                      <div className="col-md-6 col-12 max-mb-30">
                        <input
                          type="email"
                          placeholder="Email *"
                          name="email"
                          value={document.email}
                          onChange={handleInputChange}
                        />
                        <span style={{ color: "red" }}>
                          {errormail ? "Email is required" : ""}
                        </span>
                      </div>
                      <div className="col-md-12 col-12 max-mb-30">
                        <input
                          type="text"
                          placeholder="Subject *"
                          name="subject"
                          value={document.subject}
                          onChange={handleInputChange}
                        />
                        <span style={{ color: "red" }}>
                          {errorsubject ? "Subject  is required" : ""}
                        </span>
                      </div>
                      <div className="col-md-12 col-12 max-mb-30">
                        <select
                          className="course-dd"
                          placeholder="Question related to what course ?*"
                          name="course_type_id"
                          value={document.course_type_id}
                          onChange={handleInputChanges}
                          // disabled
                          style={{ color: onSelectc ? "black" : "#9f9f9f" }}
                        >
                          <option value="" disabled hidden>
                            Question related to what course ?*
                          </option>
                          {/* <option value="" style={{ color: "#9f9f9f" }}>
                           
                          </option> */}
                          {courseNames.map((val) => {
                            return (
                              <option value={val.course_type_id}>
                                {val.course_type_name}
                              </option>
                            );
                          })}
                        </select>
                        <span style={{ color: "red" }}>
                          {errCourseType ? "CourseType  is required" : ""}
                        </span>
                      </div>
                      <div className="col-12 max-mb-30">
                        <textarea
                          name="message"
                          placeholder="Message"
                          defaultValue={""}
                          value={document.message}
                          onChange={handleInputChange}
                        />
                      </div>
                      <div className="col-12 text-center max-mb-30">
                        <button
                          type="button"
                          id="submitid"
                          className="btn btn-primary btn-hover-secondary btn-width-180 btn-height-60"
                          onClick={() => formsubmit()}
                        >
                          {!dummy ? "Submit" : "Please wait..."}
                        </button>
                      </div>
                    </div>
                  </form>
                  <p className="form-messege" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Google Map Area Start*/}
      
        <div></div>
        {/*Google Map Area Start*/}
        {/* Contact Form Section Start */}
        
        {/* Contact Form Section End */}
      </div>
    </div>
  );
};

export default Contactus;
