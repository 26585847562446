import React from "react";

const ListData = () => {
  return (
    <div>
      <div className="container-fluid row">
        <h2
          style={{
            color: "#e0112a",
            padding: "10px 0",
            fontFamily: "sans-serif",
            border: "1px solid",
            width: "98%",
            margin: "13px",
            background: "rgb(224, 17, 42, 0.1)",
            borderRadius: "15px",
          }}
        >
          First Batch of Bhagavad Gita Learners
        </h2>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              <img src="https://rushi2011.s3.amazonaws.com/folder/WhatsApp Image 2021-12-14 at 11.56.11 AM.jpeg" />
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                Vidya Tadanki
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>1. Bramaramba Uruviti</p>
              <p>2. Chavali Dedeepya</p>
              <p>3. Chavali Subbalakshmi</p>
              <p>4. Kalpana Maddula</p>
              <p>5. Kanuri Padmavathi</p>
              <p>6. Niraja Dorbala</p>
              <p>7. Padma Dendukuri</p>
              <p>8. Sasikala Pasupuleti</p>
              <p>9. Satya Sarvani Mulukutla</p>
              <p>10. Srilakshmi Sarath</p>
              <p>11. Srinivasa Ramayanapu</p>
              <p>12. Sudheer Poluru</p>
              <p>13. Suneetha Kothapalli</p>
              <p>14. Udaya Mukkavilli</p>
              <p>15. Uma Godavarthi</p>
              <p>16. Vasanthi Nalluri</p>
              <p>17. Padmaja Ayyagari</p>
              <p>18. Venkata Parvati Pasumarthy</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              <img src="https://rushi2011.s3.amazonaws.com/folder/cbimage (2).png" />
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                Sadgun Kambhampati
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>1. Anandamadhuri Venkata Chunduri</p>
              <p>2. Chandrika Jonnalagadda</p>
              <p>3. Durgamba Jonnalagadda</p>
              <p>4. Naga Madhavi Duri</p>
              <p>5. Nagini Heggers</p>
              <p>6. Padmavathi Achanta</p>
              <p>7. Phanidhar Kandala</p>
              <p>8. Saraswathi Kondethimmanahally</p>
              <p>9. Sarva Srinivasa Rao Meduri</p>
              <p>10. Sastry Duri</p>
              <p>11. SeethaRam Jonnalagadda</p>
              <p>12. Suneetha Pattisapu</p>
              <p>13. Thejasvi N</p>
              <p>14. Srujana Buddhe</p>
              <p>15. Venkatesh Ghantasala</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              <img src="https://rushi2011.s3.amazonaws.com/folder/WhatsApp Image 2021-12-14 at 11.56.11 AM (6).jpeg" />
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                Santhi Akella
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>1. Abhinivesh Reddy</p>
              <p>2. Anuraag Reddy</p>
              <p>3. Aparna Lakshmi Jayanthi</p>
              <p>4. Lakshmi K</p>
              <p>5. MadhukarReddy Dudipala</p>
              <p>6. Maruthi Kumari Chilamkuri</p>
              <p>7. Radhika G</p>
              <p>8. Vamsi Rama Krishna Kasichainula</p>
              <p>9. Manikya Veena Addepalli</p>
              <p>10. Srivani Lakkavajula</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              <img src="https://rushi2011.s3.amazonaws.com/folder/WhatsApp Image 2021-12-14 at 11.56.11 AM (3).jpeg" />
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                Swati Burla
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>1. Abighnya Samavedam</p>
              <p>2. Akshith Samavedam</p>
              <p>3. Sri Agastya Bhagavatula</p>
              <p>4. Giri Ruthika Akkapeddi</p>
              <p>5. Karthik Valluru</p>
              <p>6. Kaushik Viswa Teja Bhagavatula</p>
              <p>7. Sailaja Kanchibhotla</p>
              <p>8. Srilakshmi Niharika Silivery</p>
              <p>9. Sriram Kanchibhotla</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              <img src="https://rushi2011.s3.amazonaws.com/folder/SravaniJandhyala.jpeg" />
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                Sravani Jandhyala
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>1. Aditya Duri</p>
              <p>2. Rajeswari B</p>
              <p>3. Siddhartha Duri</p>
              <p>4. Srinatha Duri</p>
              <p>5. Sudha B</p>
              <p>6. Bhanukiran Vinzamuri</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              <img src="https://rushi2011.s3.amazonaws.com/folder/WhatsApp Image 2021-12-14 at 11.56.11 AM (1).jpeg" />
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                SatyaSri Vepa
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>1. Pavani Nori</p>
              <p>2. Sreekrishna Palaparthi</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
              <p>&nbsp;</p>
            </div>
          </div>
        </div>
        <div className="col-md-3" style={{ margin: "12px 0" }}>
          <div className="t-sec">
            <div
              className="profile-image t-prof"
              style={{ borderBottom: "2px dashed #222" }}
            >
              {/* <img src="https://rushi2011.s3.amazonaws.com/folder/WhatsApp Image 2021-12-14 at 11.56.11 AM (1).jpeg" /> */}
              <h4
                class="mg-b-10"
                style={{
                  color: "#efa71b",
                  padding: "10px 0",
                  fontFamily: "sans-serif",
                }}
              >
                Memorizers
              </h4>
            </div>
            <div style={{ textAlign: "left" }}>
              <p>
                <b>1. Suma Latha Vemuri </b>
              </p>
              <p>
                <b>2. Sudhamayi Oruganti</b>
              </p>
              <p>
                <b>3. Sharada Mani Polukonda</b>
              </p>
              <p>
                <b>4. Pranavi Achanta</b>
              </p>
              <p>
                <b>5. Pushya Pelluri</b>
              </p>
              <p>
                <b>6. Kartik Vijayashanker</b>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListData;
