import React, { useState } from "react";
import Axios from "../utilities/Axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import { useNavigate } from "react-router-dom";

const Signup = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const initialFormState = {
    first_name: "",
    last_name: "",
    email_id: "",
    password: "",
    cnfpwd: "",
    phone_number: "",
    role: "student",
  };
  const navigate = useNavigate();

  const [errorfname, setErrorfname] = useState(false);
  const [errorlname, setErrorlname] = useState(false);
  const [erroremail, setErroremail] = useState(false);
  const [errorpassword, setErrorpassword] = useState(false);
  const [errorcnfp, setErrorcnfp] = useState(false);
  const [waitstatus, setWaitstatus] = React.useState(false);
  const [errormobilenumber, seterrormobilenumber] = useState(false);

  const [dummy, setDummy] = useState(false);
  const [errorsubject, setErrorsubject] = useState(false);
  const [document, setDocument] = useState(initialFormState);
  const [showpwd, setShowpwd] = React.useState(false);
  const [showcnfpwd, setShowcnfpwd] = React.useState(false);

  const showpassword = () => {
    setShowpwd(!showpwd);
  };
  const showcnfpassword = () => {
    setShowcnfpwd(!showcnfpwd);
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setDocument({ ...document, [name]: value });
  };
  const formsubmit = async () => {
    // console.log(document);
    var error = "no";
    if (document.first_name == "") {
      setErrorfname(true);
      error = "yes";
    }
    if (document.last_name == "") {
      setErrorlname(true);
      error = "yes";
    }
    if (document.email_id == "") {
      setErroremail(true);
      error = "yes";
    }
    if (document.password == "") {
      setErrorpassword(true);
      error = "yes";
    }
    if (document.cnfpwd == "") {
      setErrorcnfp(true);
      error = "yes";
    }
    if (document.phone_number == "") {
      seterrormobilenumber(true);
      error = "yes";
    }
    if (error == "no") {
      const re = new RegExp(
        "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$"
      );
      const regex = /^([0|\+[0-9]{1,5})?([0-9]{9})$/;
      const pass1 = re.test(document.password);
      const validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
      const emailchk = validEmail.test(document.email_id);
      if (document.password != document.cnfpwd) {
        alert("Password and Confirm password should be same");
      } else if (!pass1) {
        toast(
          "Password should be 8 characters with at least 1 (Capital Letter, Numeric, Special Character)"
        );
        return;
      } else if (!emailchk) {
        toast("Enter valid Email");
        return;
      } else if (!regex.test(document.phone_number)) {
        toast("Enter valid Phone Number");
        return;
      } else {
        let data = { ...document };
        const new_pass = CryptoJS.AES.encrypt(
          document.password,
          "rushi"
        ).toString();
        // data.password = new_pass;
        setDummy(true);
        setErrorfname(false);
        setErrorlname(false);
        setErroremail(false);
        setErrorpassword(false);
        setErrorcnfp(false);
        seterrormobilenumber(false);
        await Axios.post(
          "user_details/registration",
          Object.assign(data, { password: new_pass })
        )
          .then((res) => {
            console.log(res, "res");
            if (res?.data?.status === 200) {
              // alert("Registered Successfully");
              let msg = res?.data?.message;
              if (msg == "failed") {
                let error = res?.data?.error;
                if (error.includes("email_id")) {
                  var alert_msg = "Email already registered";
                  var type = "warning";
                }
                if (error.includes("phone_number")) {
                  var alert_msg = "Mobile number already registered";
                  var type = "warning";
                }
              } else {
                var alert_msg = "Registered Successfully";
                setDocument(initialFormState);
                var type = "success";
              }
              toast(alert_msg, {
                position: "bottom-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                type: type,
              });
              setDummy(false);
              if (res.data.message == "successful") {
                navigate("/login");
              }
              // history.push("/");
            }
          })
          .catch((err) => {
            console.log(err);
            //   if (err?.data.status === 400) {
            //     alert(err.data.error);
            //   }
          });
      }
    }
  };

  return (
    <div>
      <div>
        {/* Page Title Section Start */}
        <div className="page-title-section section auth-ali">
          <div className="page-breadcrumb">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Sign Up</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Page Title Section End */}
        {/*Login Register section start*/}
        <div className="login-register-section section section-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="offset-xl-3 col-xl-6 offset-xl-1 col-lg-6">
                    {/* Register Form Start */}
                    <div className="login-form-wrapper mt-sm-50 mt-xs-50">
                      {/* <h3 className="title">Register</h3> */}
                      <img
                        src="http://www.rushividyabhyasam.org/assets/images/logo/rushividyahyasam-small-logo.png"
                        alt="Learts Logo"
                        className="auth-logo"
                      />
                      <form className="register-form align-cont" action="#">
                        <div className="f-l-adjust">
                          <div className="single-input mb-30">
                            {/* <label htmlFor="usernameOne">First Name</label> */}
                            <input
                              type="text"
                              id="first_name"
                              name="first_name"
                              placeholder="First name"
                              value={document.first_name}
                              onChange={handleInputChange}
                              required
                            />
                            <span className="pwd-err">
                              {errorfname ? "Firstname is required" : ""}
                            </span>
                          </div>
                          <div className="single-input mb-30">
                            {/* <label htmlFor="usernameOne">Last Name</label> */}
                            <input
                              type="text"
                              id="last_name"
                              name="last_name"
                              placeholder="Last name"
                              value={document.last_name}
                              onChange={handleInputChange}
                              required
                            />
                            <span className="pwd-err">
                              {errorlname ? "Lastname is required" : ""}
                            </span>
                          </div>
                        </div>
                        <div className="single-input mb-30">
                          {/* <label htmlFor="email">Email</label> */}
                          <input
                            type="text"
                            id="email_id"
                            name="email_id"
                            placeholder="Email"
                            value={document.email_id}
                            onChange={handleInputChange}
                            required
                          />
                          <span className="pwd-err">
                            {erroremail ? "Email is required" : ""}
                          </span>
                        </div>
                        <div className="single-input mb-30">
                          {/* <label htmlFor="passwordOne">Password</label> */}
                          <input
                            type={showpwd ? "text" : "password"}
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={document.password}
                            onChange={handleInputChange}
                          />
                          <i
                            className={`far fa-eye${!showpwd ? "" : "-slash"}`}
                            id="togglePassword"
                            style={{ marginLeft: "-30px", cursor: "pointer" }}
                            onClick={() => showpassword()}
                          />
                          <p className="pwd-rules">
                            Password should be minimum 8 characters, with at
                            least 1 (Capital Letter, Numeric, Special
                            Characters){" "}
                          </p>
                          <span className="pwd-err">
                            {errorpassword ? "Password is required" : ""}
                          </span>
                        </div>
                        <div className="single-input mb-30">
                          {/* <label htmlFor="cnfpasswordOne">
                            Confirm Password
                          </label> */}
                          <input
                            type={showcnfpwd ? "text" : "password"}
                            id="cnfpwd"
                            name="cnfpwd"
                            placeholder="Confirm password"
                            value={document.cnfpwd}
                            onChange={handleInputChange}
                            required
                          />
                          <i
                            className={`far fa-eye${!showcnfpwd ? "" : "-slash"
                              }`}
                            id="togglePassword"
                            style={{ marginLeft: "-30px", cursor: "pointer" }}
                            onClick={() => showcnfpassword()}
                          />
                          <span className="pwd-err">
                            {errorcnfp ? "Confirm password is required" : ""}
                          </span>
                        </div>

                        <div className="single-input mb-30">
                          {/* <label htmlFor="cnfpasswordOne">Mobile Number</label> */}
                          <input
                            type="text"
                            id="phone_number"
                            name="phone_number"
                            placeholder="Mobile number"
                            value={document.phone_number}
                            onChange={handleInputChange}
                            required
                          />
                          <span className="pwd-err">
                            {errormobilenumber
                              ? "Mobile number is required"
                              : ""}
                          </span>
                        </div>

                        <div className="single-input">
                          <button
                            type="button"
                            className="btn btn-primary btn-hover-secondary btn-width-100"
                            onClick={() => formsubmit()}
                            disabled={!dummy ? "" : "disabled"}
                          >
                            {!dummy ? "Register" : "Please wait..."}
                          </button>
                        </div>
                        <p className="d-signup">
                          Already have an account ?{" "}
                          <Link to="/login">Sign in!</Link>
                        </p>
                      </form>
                    </div>
                    {/* Register Form End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Login Register section end*/}
      </div>
    </div>
  );
};

export default Signup;
