import React from "react";
import { useNavigate } from "react-router-dom";
import "../launch.css";
//document.getElementById("starter").focus();
//document.body.addEventListener('onload', focus());

const Launch = () => {
  const navigate = useNavigate();
  document.addEventListener("keydown", detectSpaceKey);

  function detectSpaceKey(event) {
    if (event.keyCode == 13) {
      showTime();
    }
  }
  function showTime() {
    var curtain = document.getElementById("curtain");
    curtain.className = "open";

    var scene = document.getElementById("scene");
    scene.className = "expand";

    var starter = document.getElementById("starter");
    starter.className = "fade-out";

    setTimeout(function () {
      starter.style.display = "none";
    }, 4000);
    setTimeout(() => {
      navigate("/");
    }, 10000);
  }

  return (
    <div>
      <div id="starter">
        <img
          src="http://www.rushividyabhyasam.org/assets/images/logo/rushividyahyasam-small-logo.png"
          alt
        />
        <span>LAUNCH</span>
      </div>
      <div id="scene">
        <div id="curtain">
          <h1>Rushividyabhyasam</h1>
          <div className="ground" />
          <div className="left" />
          <div className="right" />
        </div>
      </div>
    </div>
  );
};

export default Launch;
