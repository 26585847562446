import React from "react";

const ListOfCourses = () => {
  return (
    <div>
      {/* <div className="container-fluid row">
        <div className="page-title" style={{ padding: "25px 0 10px 0" }}>
          <div className="container">
            <h1 className="title">List Of Courses</h1>
          </div>
        </div> */}
      {/* <div className="col-md-12 list-cours">
          <div className="t-sec list-cours-cont">
            <div className="list-cours-items">
              <div className="list-cours-cont">
                <h4>
                  Jagadguru (Jan - May) <span>- Bhagavad Gita Course</span>
                </h4>
                <a
                  href="https://www.rushividyabhyasam.org/coursedetails/68311819"
                  target="_blank"
                >
                  <button className="btn btn-primary btn-hover-secondary">
                    Go to Course
                  </button>
                </a>
              </div>
              <p>
                <b>Padma Achanta</b> - (<b>US Time:</b> Mondays & Wednesdays
                11:00 AM PST, 2:00 PM EST | <b>England(UK) Time:</b> Mondays &
                Wednesdays: 7:00 PM BST)
              </p>
              <p>
                <b>SriLakshmi Garu</b> - (<b>India Time:</b> Tuesdays &
                Thursdays 3:30 PM IST | <b>Singapore Time:</b> Tuesdays &
                Thursdays 6:00 PM SGT)
              </p>
              <p>
                <b>Pranavi Achanta</b> - (<b>US Time:</b> Saturdays 5:30 AM PST,
                8:30 AM EST & Sundays 5:00 PM PST, 8:00 PM EST |{" "}
                <b>India Time:</b> Saturdays: 7:00 PM IST & Mondays: 6:30 AM
                IST)
              </p>
              <p>
                <b>Venkatesh Ghantasala</b> - (<b>US Time:</b> Sundays &
                Mondays: 6:00 PM PST, 9:00 PM EST | <b>India Time:</b> Mondays &
                Tuesdays: 7:30 AM IST)
              </p>
              <p>
                <b>Sravani Jandhyala</b> - (<b>US Time:</b> Wednesdays 6:00 PM
                PST, 9:00 PM EST & Thursdays 5:00 PM PST, 8:00 PM EST |{" "}
                <b>India Time:</b> Thursdays : 7:30 AM IST & Fridays: 6:30 AM
                IST)
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-12 list-cours">
          <div className="t-sec list-cours-cont">
            <div className="list-cours-items">
              <div className="list-cours-cont">
                <h4>
                  Geetacharya (Jan - May) <span>- Bhagavad Gita Course</span>
                </h4>
                <a
                  href="https://www.rushividyabhyasam.org/coursedetails/21783752"
                  target="_blank"
                >
                  <button className="btn btn-primary btn-hover-secondary">
                    Go to Course
                  </button>
                </a>
              </div>
              <p>
                <b>Padmavathi Kanuri</b> - (<b>US Time:</b> Sundays & Thursdays:
                6:00 AM PST, 9:00 AM EST | <b>India Time:</b> Sundays &
                Thursdays: 7:30 PM IST)
              </p>
              <p>
                <b>Padmaja Ayyagari</b> - (<b>US Time:</b> Tuesdays & Fridays:
                4:30 PM PST, 7:30 PM EST | <b>India Time:</b> Wednesdays &
                Saturdays : 6:00 AM IST)
              </p>
              <p>
                <b>Sharada Mani</b> - (<b>US Time:</b> Mondays & Thursdays: 4:30
                PM PST, 7:30 PM EST | <b>India Time:</b> Tuesdays & Fridays :
                6:00 AM IST)
              </p>
              <p>
                <b>Lakshmi</b> - (<b>US Time:</b> Sundays & Wednesdays: 6:00 PM
                PST, 9:00 PM EST | <b>India Time:</b> Mondays & Thursdays: 7:30
                AM IST)
              </p>
            </div>
          </div>
        </div> */}
      <div className="wishlist-section section section-padding-bottom">
        <div className="container-fluid row cour-tbl">
          <div className="page-title" style={{ padding: "25px 0 10px 0" }}>
            <div className="container">
              <h2 className="title">Bhagavad Gita Course Segments</h2>
            </div>
          </div>
          <div className="col-md-12 gita-points">
            <ul>
              <li>
                There are 4 segments in the programs, a new participant can join
                in any segment and complete the entire cycle from there on.
              </li>
              <li>
                Enrollments are open for all the segments of the program. Please
                refer to the illustration alongside for complete details.
              </li>
              <li>
                A new participant can join at any time during the registration
                cycles. Each segment takes around 6 months to complete.
              </li>
              <li>
                At the end of each segments, there is an assessment and a
                thorough 'graduation process' making sure that participants have
                completed the study.
              </li>
              <li>
                Quizzes are conducted after after 20-30 slokas or after every
                chapter- to make sure that there is a complete understanding of
                the slokas.
              </li>
              <li>
                Meanings & true Krishna Hrudayam is understood by making sure
                that the participants listen to Pujya Guruvugari pravachanam
                systematically.{" "}
              </li>
            </ul>
          </div>
          <div className="col-md-12">
            <div className="cart-table table-responsive">
              <table className="table cour-tbl-sub">
                <thead>
                  <tr>
                    <th>Segment</th>
                    <th>Slot</th>
                    <th>Instructor Name</th>
                    <th>US Day</th>
                    <th>US PST</th>
                    <th>US EST</th>
                    <th>India Day</th>
                    <th>IST</th>
                    <th>England Day</th>
                    <th>UK</th>
                    <th>Singapore Day</th>
                    <th>SGT</th>
                    <th id="co-btn">Go to Course</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td rowSpan={10}>Jagadguru (Jan - May)</td>
                    <td rowSpan={2}>Slot 1</td>
                    <td rowSpan={2}>Padma Achanta</td>
                    <td>Mondays</td>
                    <td>11:00 AM</td>
                    <td>2:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Mondays</td>
                    <td>7:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td rowSpan={10} id="co-btn">
                      <a
                        href="https://www.rushividyabhyasam.org/coursedetails/68311819"
                        target="_blank"
                      >
                        <button className="btn btn-primary btn-hover-secondary">
                          Go to Course
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Wednesdays</td>
                    <td>11:00 AM</td>
                    <td>2:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Wednesdays</td>
                    <td>7:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 2</td>
                    <td rowSpan={2}>SriLakshmi</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Tuesdays</td>
                    <td>3:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Tuesdays </td>
                    <td>6:00 PM</td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Thursdays</td>
                    <td>3:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Thursdays</td>
                    <td>6:00 PM</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 3</td>
                    <td rowSpan={2}>Pranavi Achanta</td>
                    <td>Saturdays</td>
                    <td>5:30 AM</td>
                    <td>8:30 AM</td>
                    <td>Saturdays</td>
                    <td>7:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Sundays</td>
                    <td>5:00 PM</td>
                    <td>8:00 PM</td>
                    <td>Mondays</td>
                    <td>6:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 4</td>
                    <td rowSpan={2}>Venkatesh Ghantasala</td>
                    <td>Sundays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Mondays</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Mondays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Tuesdays</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 5</td>
                    <td rowSpan={2}>Sravani Jandhyala</td>
                    <td>Wednesdays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Thursdays</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Thursdays</td>
                    <td>5:00 PM</td>
                    <td>8:00 PM</td>
                    <td>Fridays</td>
                    <td>6:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={8}>Geetacharya (Jan - May)</td>
                    <td rowSpan={2}>Slot 1</td>
                    <td rowSpan={2}>Padmavathi Kanuri</td>
                    <td>Sundays</td>
                    <td>6:00 AM</td>
                    <td>9:00 AM</td>
                    <td>Sundays</td>
                    <td>7:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td rowSpan={8} id="co-btn">
                      <a
                        href="https://www.rushividyabhyasam.org/coursedetails/21783752"
                        target="_blank"
                      >
                        <button className="btn btn-primary btn-hover-secondary">
                          Go to Course
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Thursdays</td>
                    <td>6:00 AM</td>
                    <td>9:00 AM</td>
                    <td>Thursdays</td>
                    <td>7:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 2</td>
                    <td rowSpan={2}>Padmaja Ayyagari</td>
                    <td>Tuesdays</td>
                    <td>4:30 PM</td>
                    <td>7:30 PM</td>
                    <td>Wednesdays</td>
                    <td>6:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Fridays</td>
                    <td>4:30 PM</td>
                    <td>7:30 PM</td>
                    <td>Saturdays</td>
                    <td>6:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 3</td>
                    <td rowSpan={2}>Sharada Mani</td>
                    <td>Mondays</td>
                    <td>4:30 PM</td>
                    <td>7:30 PM</td>
                    <td>Tuesdays</td>
                    <td>6:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Thursdays</td>
                    <td>4:30 PM</td>
                    <td>7:30 PM</td>
                    <td>Fridays</td>
                    <td>6:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 4</td>
                    <td rowSpan={2}>Lakshmi</td>
                    <td>Sundays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Mondays</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Wednesdays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Thursdays</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>Yogeshwara (Jan - May)</td>
                    <td rowSpan={2}>Slot 1</td>
                    <td rowSpan={2}>Satyasri Vepa</td>
                    <td>Saturdays</td>
                    <td>4:30 PM</td>
                    <td>7:30 PM</td>
                    <td>Sundays</td>
                    <td>6:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td rowSpan={6} id="co-btn">
                      <a
                        href="https://www.rushividyabhyasam.org/coursedetails/19135783"
                        target="_blank"
                      >
                        <button className="btn btn-primary btn-hover-secondary">
                          Go to Course
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Mondays</td>
                    <td>4:30 PM</td>
                    <td>7:30 PM</td>
                    <td>Tuesdays</td>
                    <td>6:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 2</td>
                    <td rowSpan={2}>Santhi Akella</td>
                    <td>Saturdays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Saturdays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Sundays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Sundays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 3</td>
                    <td rowSpan={2}>Divakar Jammalamdaka</td>
                    <td>Tuesdays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Wednesday</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Thursdays</td>
                    <td>6:00 PM</td>
                    <td>9:00 PM</td>
                    <td>Friday</td>
                    <td>7:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>Akshra Brahma (Jan - May)</td>
                    <td rowSpan={2}>Slot 1</td>
                    <td rowSpan={2}>Vidya Tadanki</td>
                    <td>Tuesdays</td>
                    <td>5:00 PM</td>
                    <td>8:00 PM</td>
                    <td>Wednesday</td>
                    <td>6:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td rowSpan={6} id="co-btn">
                      <a
                        href="https://www.rushividyabhyasam.org/coursedetails/24233114"
                        target="_blank"
                      >
                        <button className="btn btn-primary btn-hover-secondary">
                          Go to Course
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>Thursdays</td>
                    <td>5:00 PM</td>
                    <td>8:00 PM</td>
                    <td>Friday</td>
                    <td>6:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 2</td>
                    <td rowSpan={2}>Sadgun Kambhampati</td>
                    <td>Wednesdays</td>
                    <td>5:00 PM</td>
                    <td>8:00 PM</td>
                    <td>Thursdays</td>
                    <td>6:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Fridays</td>
                    <td>5:00 PM</td>
                    <td>8:00 PM</td>
                    <td>Saturdays</td>
                    <td>6:30 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 3</td>
                    <td rowSpan={2}>Rajeshwari Pusuluri</td>
                    <td>Saturdays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Saturdays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Sundays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Sundays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={6}>Jagadguru Kids Only (Jan - May)</td>
                    <td rowSpan={2}>Slot 1</td>
                    <td rowSpan={2}>Durgamba Jonnalagadda</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Tuesdays</td>
                    <td>6:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Tuesdays</td>
                    <td>8:30 PM</td>
                    <td rowSpan={6} id="co-btn">
                      <a
                        href="https://www.rushividyabhyasam.org/coursedetails/63953381"
                        target="_blank"
                      >
                        <button className="btn btn-primary btn-hover-secondary">
                          Go to Course
                        </button>
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Sundays</td>
                    <td>6:00 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>Sundays</td>
                    <td>8:30 PM</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 2</td>
                    <td rowSpan={2}>Satya Sarvani</td>
                    <td>Tuesdays</td>
                    <td>7:30 PM</td>
                    <td>10:30 PM</td>
                    <td>Wednesdays</td>
                    <td>9:00 AM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Saturdays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Saturdays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td rowSpan={2}>Slot 3</td>
                    <td rowSpan={2}>Radhika & Aparna</td>
                    <td>Tuesdays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Tuesdays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                  <tr>
                    <td>Wednesdays</td>
                    <td>7:00 AM</td>
                    <td>10:00 AM</td>
                    <td>Wednesdays</td>
                    <td>8:30 PM</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListOfCourses;
