import React, { useState } from "react";
import Axios from "../utilities/Axios";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import CryptoJS from "crypto-js";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
const Forgotpassword = () => {
  const initialFormState = {
    first_name: "",
    last_name: "",
    email_id: "",
    password: "",
    cnfpwd: "",
    phone_number: "",
    role: "student",
  };
  const [values, setValues] = React.useState({
    password: "",
    Cpassword: "",
    showCPassword: false,
    showPassword: false,
  });
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  let [userOtp, setUserOtp] = React.useState("");
  const [dataShow, setDataShow] = React.useState(false);
  const [pleasewait, setPleasewait] = React.useState(false);
  const [email_id, setEmail_Id] = React.useState("");
  const [otpvalid, setOtpvalid] = React.useState(false);
  const [originalOtp, setOriginalOtp] = React.useState("");
  const [visible, setVisible] = React.useState(false);
  const [mailvisible, setmailvisible] = React.useState(true);
  const [resendstatus, setResendstatus] = React.useState(false);
  const [erroremail, setErroremail] = useState(false);
  const [validemail, setValidemail] = useState(false);
  const [errorpassword, setErrorpassword] = useState(false);
  const [errorcnfp, setErrorcnfp] = useState(false);
  const [errorotp, setErrorotp] = useState(false);

  const [document, setDocument] = useState(initialFormState);
  const [showpwd, setShowpwd] = React.useState(false);
  const [showcnfpwd, setShowcnfpwd] = React.useState(false);
  const handleInputChange = (event) => {
    const { name, value } = event.target;

    setDocument({ ...document, [name]: value });
  };
  const showpassword = () =>{
    setShowpwd(!showpwd)
  }
  const showcnfpassword = () =>{
    setShowcnfpwd(!showcnfpwd)
  }
  const handleNext = () => {
    let data = { email_id: email_id };
    const validEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    const emailchk = validEmail.test(email_id);

    if (email_id == "") {
      setErroremail(true);
      setValidemail(false);
    } else if (!emailchk) {
      setErroremail(false);
      setValidemail(true);

      setEmail_Id("");
      return;
    } else {
      setPleasewait(true);
      fun(data);
    }
  };

  const fun = (data) => {
    setErroremail(false);
    setValidemail(false);
    Axios.post("user_details/forgetPassword_otp_send", data)
      .then((res) => {
        // console.log(res.data, "Response 44444444444");
        if (res.data.message === "please enter registered email id") {
          toast(res.data.message, {
            type: "error",
            theme: "dark",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        } else {
          toast(`We have sent the OTP to your email`, {
            type: "success",
            theme: "dark",
            position: "top-center",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setOriginalOtp(res.data.otp);
          setDataShow(true);
          setPleasewait(false);
          setmailvisible(false);
          setResendstatus(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleOTP = (otp) => {
    setUserOtp(otp);
    if (otp === originalOtp) {
      setVisible(true);
      setDataShow(false);
      setErrorotp(false);
    } else {
      setOtpvalid(true);
      if (otp.length === 4) {
        setErrorotp(true);
      } else {
        setErrorotp(false);
      }

      setVisible(false);
    }
  };
  const navigate = useNavigate();
  const handleSubmit = () => {
    let error = "no";
    if (values.password == "") {
      setErrorpassword(true);
      error = "yes";
    }
    if (values.Cpassword == "") {
      setErrorcnfp(true);
      error = "yes";
    }
    const re = new RegExp("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,20}$");
    const pass1 = re.test(values.password);
    if (error == "no") {
      if (!pass1) {
        setErrorpassword(false);
        setErrorcnfp(false);
        alert("Password Format not valid...");
        error = "yes";
      }
    }
    if (error == "no") {
      setErrorpassword(false);
      setErrorcnfp(false);
      // alert("You have successfully changed your password");
      if (values.password === values.Cpassword) {
        const new_pass = CryptoJS.AES.encrypt(
          values.password,
          "rushi"
        ).toString();
        let data = {
          email_id: email_id,
          new_password: new_pass,
        };
        // console.log(data, "Data Sending.....");
        Axios.post("user_details/changepassword_otp", data)
          .then((res) => {
            // console.log(res.data.status, "Response");
            if (res.data.status === 200) {
              toast(res.data.message, {
                type: "success",
                theme: "dark",
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
              });
              navigate("/login");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        toast("Password and Confirm Password should be same", {
          type: "error",
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };
  const handleResend = () => {
    let data = { email_id: email_id };
    setResendstatus(true);
    fun(data);
  };
  return (
    <div>
      <div>
        {/* Page Title Section Start */}
        <div className="page-title-section section auth-ali">
          {/* <div className="page-title">
            <div className="container">
              <h1 className="title">Forgot password</h1>
            </div>
          </div> */}
          <div className="page-breadcrumb">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Forgot Password</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Page Title Section End */}
        {/*Login Register section start*/}
        <div className="login-register-section section section-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className="offset-xl-3 col-xl-6 offset-xl-1 col-lg-6">
                    {/* Register Form Start */}
                    <div className="login-form-wrapper mt-sm-50 mt-xs-50">
                      <form className="register-form align-cont" action="#">
                        {mailvisible ? (
                          <div id="emaildiv">
                            <div className="single-input mb-30">
                              <input
                                type="email"
                                id="email_id"
                                name="email_id"
                                placeholder="Enter Registered Email"
                                value={email_id}
                                onChange={(e) => setEmail_Id(e.target.value)}
                                required
                              />
                              <span className="pwd-err">
                                {erroremail ? "Email is required" : ""}
                              </span>
                              <span className="pwd-err">
                                {validemail ? "Enter valid Email" : ""}
                              </span>
                            </div>

                            <div className="single-input">
                              <button
                                type="button"
                                className="btn btn-primary btn-hover-secondary btn-width-100"
                                onClick={() => handleNext()}
                              >
                                {pleasewait ? "Please wait.." : `Next`}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {dataShow ? (
                          <>
                            <div className="col-md-12 o-t-p mg-b-10 xza">
                              <OtpInput
                                value={userOtp}
                                onChange={(userOtp) => handleOTP(userOtp)}
                                numInputs={4}
                                separator={
                                  //   <center>
                                  <span> - </span>
                                  //   </center>
                                }
                                style={{
                                  border: `${!otpvalid}? "" : "2px solid red !important"`,
                                }}
                                placeholder="****"
                                shouldAutoFocus={true}
                                style={{ width: "100%" }}
                                isInputNum
                              />
                              <span className="pwd-err">
                                {errorotp ? "Invalid OTP" : ""}
                              </span>
                            </div>
                            <div className="sign-up mg-b-20 pre-link r-send">
                              If you don't receive a code{" "}
                              <span
                                className="pwd-err"
                                style={{ cursor: "pointer" }}
                                onClick={handleResend}
                              >
                                {resendstatus ? "Please Wait.." : "Resend"}
                              </span>
                            </div>
                          </>
                        ) : (
                          ""
                        )}
                        {visible ? (
                          <div id="passworddiv">
                            <div className="single-input mb-30">
                              <input
                                type={showpwd ?'text':'password'}
                                id="password"
                                name="password"
                                placeholder="New Password"
                                value={values.password}
                                onChange={handleChange("password")}
                              />
                              <i className={`far fa-eye${!showpwd ?'':'-slash'}`} id="togglePassword" style={{marginLeft: '-30px', cursor: 'pointer'}} onClick={()=>showpassword()}/>
                              <span className="pwd-err">
                                {errorpassword ? "Password is required" : ""}
                              </span>
                            </div>
                            <div className="single-input mb-30">
                              <input
                                type={showcnfpwd ?'text':'password'}
                                id="Cpassword"
                                name="Cpassword"
                                placeholder="New Confirm Password"
                                value={values.Cpassword}
                                onChange={handleChange("Cpassword")}
                                required
                              />
                               <i className={`far fa-eye${!showcnfpwd ?'':'-slash'}`} id="togglePassword" style={{marginLeft: '-30px', cursor: 'pointer'}} onClick={()=>showcnfpassword()}/>
                              <span className="pwd-err">
                                {errorcnfp
                                  ? "New confirm password is required"
                                  : ""}
                              </span>
                            </div>
                            <div className="single-input">
                              <button
                                type="button"
                                className="btn btn-primary btn-hover-secondary btn-width-100"
                                onClick={() => handleSubmit()}
                              >
                                {pleasewait ? "Please wait.." : `Submit`}
                              </button>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </form>
                    </div>
                    {/* Register Form End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Login Register section end*/}
      </div>
    </div>
  );
};

export default Forgotpassword;
