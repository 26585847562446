import React, { useRef } from "react";
import SimpleReactValidator from "simple-react-validator";
import {
  WRONG_EMAIL,
  WRONG_FULLNAME,
  WRONG_PASSWORD,
  WRONG_PHONE_NUMBER,
  WRONG_SUBJECT,
} from "./constants.js";

export const Validator = () => {
  return useRef(
    new SimpleReactValidator({
      validators: {
        email: {
          message: WRONG_EMAIL,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        password: {
          message: WRONG_PASSWORD,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(
              val,
              /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,16}$/
            );
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        fullname: {
          message: WRONG_FULLNAME,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z\s]*$/);
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        subject: {
          message: WRONG_SUBJECT,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[a-zA-Z\s]*$/);
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },

        phone_number: {
          message: WRONG_PHONE_NUMBER,
          rule: (val, params, validator) => {
            return validator.helpers.testRegex(val, /^[6-9]\d{9}$/);
          },
          messageReplace: (message, params) =>
            message.replace("", this.helpers.toSentence(params)),
          required: true,
        },
        element: (message) => <span className="error-message">{message}</span>,
      },
    })
  );
};
