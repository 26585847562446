import React, { useRef } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function Mobilemenu() {
  const navigate = useNavigate();
  const Logout = () => {
    localStorage.removeItem("uid");

    navigate("/");
  };
  const ref = useRef();

  const closepop = (e) => {
    //e.preventDefault();
    ref.current.click();
    // alert();
  };
  return (
    <div>
      <div id="site-main-mobile-menu" className="site-main-mobile-menu">
        <div className="site-main-mobile-menu-inner">
          <div className="mobile-menu-header">
            <div className="mobile-menu-logo">
              <Link to="/">
                <img
                  src="http://www.rushividyabhyasam.org/assets/images/logo/logo-web-v5.png"
                  alt
                />
              </Link>
            </div>
            <div className="mobile-menu-close">
              <button className="toggle" ref={ref}>
                <i className="icon-top" />
                <i className="icon-bottom" />
              </button>
            </div>
          </div>
          <div className="mobile-menu-content">
            <nav className="site-mobile-menu">
              <ul>
                <li className="position-static">
                  <Link
                    to="/"
                    onClick={(e) => {
                      closepop(e);
                    }}
                  >
                    <span className="menu-text">Home</span>
                  </Link>
                </li>
                <li>
                  <Link to="/aboutus" onClick={(e) => {
                      closepop(e);
                    }}>
                    <span className="menu-text">About us</span>
                  </Link>
                </li>
                <li>
                  <Link to="/course" onClick={(e) => {
                      closepop(e);
                    }}>
                    <span className="menu-text">Courses</span>
                  </Link>
                </li>
                <li>
                  <Link to="/contactus" onClick={(e) => {
                      closepop(e);
                    }}>
                    <span className="menu-text">Contact us</span>
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
