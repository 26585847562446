import React, { useEffect } from 'react'
import Axios from '../utilities/Axios'
// import Mobilemenu from "./Mobilemenu";
import { Link } from 'react-router-dom'
// import { Carousel } from "react-responsive-carousel";
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
export default function Home() {
  React.useEffect(() => {
    window.scrollTo(0, 0)
    checkCountry()
  }, [])
  const checkCountry = async () => {
    try {
      const uid = localStorage.getItem('uid')
      const res = await Axios.get(`user_details/get_user_country/${uid}`)
      console.log(res, 'country')
      if (!res?.data?.data[0]?.country) {
        localStorage.setItem('isUpdated', false)
      } else {
        localStorage.setItem('country', res?.data?.data[0]?.country)
        localStorage.setItem('isUpdated', true)
      }
    } catch (err) {
      console.log(err, 'err')
    }
  }
  // const [latlng, setLatlng] = useState({
  //   lat: 0,
  //   long: 0,
  // });
  const [data, setData] = React.useState([])

  const navigate = useNavigate()
  const imageClicked = (id, price, status) => {
    if (status != 'coming_soon') {
      navigate(`/coursedetails/${id}`)
      localStorage.setItem('price', price)
    }
  }
  useEffect(() => {
    getMyLocation()
  }, [])
  // useEffect(() => {
  //   callApi();
  // }, []);
  const getMyLocation = () => {
    axios
      .get('https://geolocation-db.com/json/')
      .then((res) => {
        // console.log(res.data,"ressss")
        let r = res?.data
        callApi(r.latitude, r.longitude, r.country_code, r.country_name)
      })
      .catch((err) => {
        console.log(err)
      })
  }
  // useEffect(() => {
  //   callApi();
  // }, []);
  //   const getMyLocation = () => {
  //     const location = window.navigator && window.navigator.geolocation;
  // // alert(JSON.stringify(location));
  //     if (location) {
  //       location.getCurrentPosition(
  //         (position) => {
  //           console.log("position.coords.latitude", position.coords.latitude);
  //           console.log(
  //             "position.coords.latitude",
  //             position.coords.longitude
  //           );
  //           let latlngs = {
  //             lat: position.coords.latitude,
  //             long: position.coords.longitude,
  //           };
  //           //setLatlng(latlngs);
  //           callApi(latlngs);
  //         }

  //         // (error) => {
  //         //   this.setState({
  //         //     latitude: "err-latitude",
  //         //     longitude: "err-longitude",
  //         //   });
  //         // }
  //       );
  //     } else {
  //       //alert('');
  //     }
  //   };

  const callApi = async (lat, long, ccode, cname) => {
    localStorage.setItem('lat', lat)
    localStorage.setItem('lng', long)
    if (!!localStorage.getItem('uid')) {
      localStorage.setItem('cname', localStorage.getItem('country'))
    } else {
      localStorage.setItem('ccode', ccode)
      localStorage.setItem("cname", cname);
    }
    const user_id = localStorage.getItem('uid')
      ? localStorage.getItem('uid')
      : 0
    let dataToSend = {
      lat: lat,
      long: long,
      user_id: user_id,
      country_code: ccode,
      country_name: cname,
    }
    await Axios.post('courses/get_all_courses_for_user', dataToSend)
      .then((res) => {
        let d = res.data.data[0]
        setData(d)

        // localStorage.setItem("lat", 17.4486);
        // localStorage.setItem("lng", 78.3908);
      })
      .catch((err) => {})
  }

  return (
    <div>
      <div>
        <div id="page" className="section">
          {/* Slider/Intro Section Start */}
          <div
            className="intro1-section section section-fluid"
            data-bg-image="assets/images/intro/intro1/bg-1.jpg"
          >
            <div className="container">
              <div className="row row-cols-md-1 row-cols-1 max-mb-n30">
                {/* Intro One Content Start */}
                <div
                  className="col align-self-center max-mb-30"
                  data-aos="fade-up"
                >
                  <div className="intro1-content text-center text-md-start">
                    {/* <span className="sub-title">Rushividyabhyasam</span> */}
                    {/* <h2 className="title b-heading">
                      Bramhasri Saamavedam <br />
                      Shanmukha Sarma
                    </h2> */}
                    <div className="desc b-desc">
                      <p>
                        <span>Brahmasri Samavedam Shanmukha Sarma</span> garu
                        has influenced innumerable people across the globe and
                        created a positive impact in their lives with his
                        discourses and writings. To aid serious aspirants to
                        continue their spiritual journey from Shravanam into
                        Mananam and Nidhi Dhyasa and also pass down the same to
                        the next generation, Sri Guruvu Garu has blessed the
                        commencement of Rushividyabhyasam, an online university.
                        The university hosts all educational programs being run
                        under the umbrella of Rushipeetam to cater to audience
                        across various age groups and varied subjects.
                      </p>
                    </div>
                    <Link
                      to="/course"
                      className="btn btn-primary btn-hover-secondary"
                    >
                      <i className="far fa-eye me-3" /> View Courses
                    </Link>
                  </div>
                </div>
                {/* Intro One Content End */}
              </div>
            </div>
            {/* Section Bottom Shape Start */}
            <div className="section-bottom-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                height={100}
              >
                <path d="M 0 0 L100 0 Q 50 200 0 0" />
              </svg>
            </div>
            {/* Section Bottom Shape End */}
          </div>
          {/* Slider/Intro Section End */}
          {/* Feature Section Start */}
          <div className="section">
            <div className="container">
              {/* Section Title Start */}
              <div className="section-title text-center" data-aos="fade-up">
                <span className="sub-title">Rushi vidya for Everyone</span>
                <h2 className="title">
                  Value Based Learning Through<span> Rushipeetham's</span>{' '}
                  Online Courses.
                </h2>
              </div>
              {/* Section Title End */}
              {/* Feature Wrapper Start */}
              <div className="row row-cols-xl-4 row-cols-sm-2 row-cols-1 g-0">
                {/* Feature Start (Icon Box) */}
                <div className="col" data-aos="fade-up">
                  <a
                    href="/#"
                    className="icon-box text-center"
                    data-vivus-hover
                  >
                    <div className="icon">
                      <img
                        className="svgInject"
                        src="assets/images/svg/linea/linea-basic-flag2.svg"
                        alt="svg"
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">Setting Meaningful Goals</h3>
                      <div className="desc">
                        <p>
                          Channalize your time and energy to set meaningful
                          goals from the learnings of the Bhagavad Gita etc.
                        </p>
                      </div>
                      {/* <span className="link">
                        Start here <i className="far fa-long-arrow-right" />
                      </span> */}
                    </div>
                  </a>
                </div>
                {/* Feature Start (Icon Box) */}
                {/* Feature Start (Icon Box) */}
                <div className="col" data-aos="fade-up">
                  <a
                    href="/#"
                    className="icon-box text-center"
                    data-vivus-hover
                  >
                    <div className="icon">
                      <img
                        className="svgInject"
                        src="assets/images/svg/linea/linea-basic-gear.svg"
                        alt="svg"
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">Character Development</h3>
                      <div className="desc">
                        <p>
                          Build a holistic character with depth, personality,
                          and clear motivations through stories from our Puranas
                          and Itihasas.
                        </p>
                      </div>
                      {/* <span className="link">
                        Discover now <i className="far fa-long-arrow-right" />
                      </span> */}
                    </div>
                  </a>
                </div>
                {/* Feature Start (Icon Box) */}
                {/* Feature Start (Icon Box) */}
                <div className="col" data-aos="fade-up">
                  <a
                    href="////#"
                    className="icon-box text-center"
                    data-vivus-hover
                  >
                    <div className="icon">
                      <img
                        className="svgInject"
                        src="assets/images/svg/linea/linea-basic-life-buoy.svg"
                        alt="jkadshf"
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">Connect with Your Culture</h3>
                      <div className="desc">
                        <p>
                          Get to know your cultural roots and identity from the
                          teachings in our scriptures.
                        </p>
                      </div>
                      {/* <span className="link">
                        Get Free Quote <i className="far fa-long-arrow-right" />
                      </span> */}
                    </div>
                  </a>
                </div>
                {/* Feature Start (Icon Box) */}
                {/* Feature Start (Icon Box) */}
                <div className="col" data-aos="fade-up">
                  <a href="#" className="icon-box text-center" data-vivus-hover>
                    <div className="icon">
                      <img
                        className="svgInject"
                        src="assets/images/svg/linea/linea-basic-display.svg"
                        alt
                      />
                    </div>
                    <div className="content">
                      <h3 className="title">
                        Remote <br />
                        Learning
                      </h3>
                      <div className="desc">
                        <p>
                          Connect with us from anywhere in the world on desktop
                          or mobile device with an Internet connection.
                        </p>
                      </div>
                      {/* <span className="link">
                        Start now <i className="far fa-long-arrow-right" />
                      </span> */}
                    </div>
                  </a>
                </div>
                {/* Feature Start (Icon Box) */}
              </div>
              {/* Feature Wrapper End */}
            </div>
          </div>
          {/* Feature Section End */}
          {/* Teacher Quote Section Start */}
          {/* <div className="section section-padding">
            <div className="container">
              <div className="teacher-quote-wrapper" data-aos="fade-up">
                <div className="teacher-quote">
                  <div className="image">
                    <img src="assets/images/others/about-profile.jpg" alt />
                  </div>
                  <div className="content">
                    <div className="section-title">
                      <h2 className="title">
                        Why Choose Us <span>Rushividyabhyasam</span>
                      </h2>
                    </div>
                    <div className="row">
                      <div className="quote f-w">
                        <p>
                          Rushividyabhyasam brought forth the secular and
                          spiritual knowledge, wishing the universal well-being
                          and happiness. The hidden eternal values, the latent
                          knowledge, unknown pearls of wisdom ought to be
                          searched, channelized and to be manifested by
                          assimilating India’s greatness.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          {/* Teacher Quote Section End */}

          {/* Video Section Start */}
          <div
            id="video-section-1"
            className="section bg-pattern position-relative"
            data-bg-image="assets/images/bg/background-pattern-grid-line.png"
            data-bg-color="#f5f1ed"
          >
            <div className="container">
              {/* Section Title Start */}
              <div
                className="section-title text-center mg-t-40"
                data-aos="fade-up"
              >
                {/* <span className="sub-title">
                  The combination of all senses into learning
                </span> */}
                <h2 className="title">
                  About Rushividyabhyasam in <span>Guruvu Garu's words</span>
                </h2>
              </div>
              {/* Section Title End */}
              {/* About Me Video Wrapper Start */}
              <div className="about-me-video-wrapper">
                {/* About Me Video Start */}
                <a
                  href="https://www.youtube.com/watch?v=0GCeVHss8CY"
                  className="about-me-video video-popup"
                  data-aos="fade-up"
                >
                  {/* <img
                    className="image"
                    src="assets/images/about/about-me/about-me-popup-video-poster.jpg"
                    alt
                  /> */}
                  <img
                    className="image"
                    src="assets/images/about/about-me/video-poster-v2.jpg"
                    alt
                  />
                  <img
                    className="icon"
                    src="assets/images/icons/icon-youtube-play.png"
                    alt
                  />
                </a>
                {/* About Me Video End */}
                {/* Animation Shape Start */}
                {/* <div className="shape shape-1 scene">
                  <span data-depth={3}>
                    <img
                      src="assets/images/shape-animation/shape-2.svg"
                      alt
                      className="svgInject"
                    />
                  </span>
                </div> */}
                <div className="shape shape-2 scene">
                  <span data-depth={-3}>
                    <img src="assets/images/shape-animation/shape-3.png" alt />
                  </span>
                </div>
                <div className="shape shape-3 scene">
                  <span data-depth={4}>shape 3</span>
                </div>
                <div className="shape shape-4 scene">
                  <span data-depth={4}>
                    <img src="assets/images/shape-animation/shape-1.png" alt />
                  </span>
                </div>
                {/* Animation Shape End */}
              </div>
              {/* About Me Video Wrapper End */}
            </div>
            {/* Section Bottom Shape Start */}
            <div className="section-bottom-shape">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                preserveAspectRatio="none"
                height={100}
              >
                <path
                  className="elementor-shape-fill"
                  d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"
                />
              </svg>
            </div>
            {/* Section Bottom Shape End */}
          </div>
          {/* Video Section End */}
          {/* Course Section Start */}
          <div className="section section-padding">
            <div className="container">
              {/* Section Title Start */}
              <div className="section-title text-center" data-aos="fade-up">
                {/* <span className="sub-title">Learn at your Pace</span> */}
                <h2 className="title">
                  Current Signature Courses
                  <a className="link link-lg view-cr" href="/course">
                    <mark>
                      View all courses <i className="far fa-long-arrow-right" />
                    </mark>
                  </a>
                </h2>
              </div>
              {/* Section Title End */}
              {/* Course Wrapper Start */}
              <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
                {/* Course Start */}
                {/* {console.log("datatata", data)} */}
                {data?.map((item, index) => {
                  if (index <= 2)
                    return (
                      <div className="col max-mb-30" data-aos="fade-up">
                        <div className="course-2">
                          <div className="thumbnail">
                            <a
                              className="image"
                              onClick={() =>
                                imageClicked(item.id, item.price, item.status)
                              }
                            >
                              <img
                                src={item.image}
                                alt="Course Image"
                                className="c-s"
                              />
                            </a>
                          </div>
                          <div className="info">
                            {item.price && item.status != 'coming_soon' && (
                              <span className="price p-small">
                                {item.price} / {item.course_duration}
                              </span>
                            )}
                            {item.status == 'coming_soon' && (
                              <span className="price p-small c-s-c">
                                Coming soon
                              </span>
                            )}

                            <h3 className="title title-tra">
                              <a
                                onClick={() =>
                                  imageClicked(item.id, item.price, item.status)
                                }
                              >
                                {item.course_name}
                              </a>
                            </h3>
                            <h6 className="cour-lab">
                              {item?.type !== null
                                ? item.type != '' && '( ' + item.type + ' )'
                                : ''}
                            </h6>
                          </div>
                        </div>
                      </div>
                    )
                })}

                {/* Course End */}
              </div>
              {/* Course Wrapper End */}
              {/* <div className="row max-mt-70">
                <div className="text-center col-lg-7 mx-auto">
                  <a className="link link-lg" href="/course">
                    Get the most dedicated consultation for your life-changing
                    course. Earn a certification for your effort and passion{" "}
                    <mark>
                      Get Free Guide <i className="far fa-long-arrow-right" />
                    </mark>
                  </a>
                </div>
              </div> */}
            </div>
          </div>
          {/* Course Section End */}

          {/* <Carousel>
            <div class="swiper-slide">
                                    <div class="testimonial-four">
                                        <div class="content">
                                            <h3 class="title">Let passion and determination be the guide along the way and develop at your own pace that's comfortable.</h3>
                                        </div>
                                        <div class="author-info">
                                            <div class="cite">
                                                <h6 class="name">Florence Themes</h6>
                                                <span class="position">/ Multimedia Admin</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>

          <Carousel>
            <div class="swiper-slide">
              <div class="testimonial-four">
                <div class="content">
                  <h3 class="title">
                    Let passion and determination be the guide along the way and
                    develop at your own pace that's comfortable.
                  </h3>
                </div>
                <div class="author-info">
                  <div class="cite">
                    <h6 class="name">Florence Themes</h6>
                    <span class="position">/ Multimedia Admin</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="testimonial-four">
                <div class="content">
                  <h3 class="title">
                    Let passion and determination be the guide along the way and
                    develop at your own pace that's comfortable.
                  </h3>
                </div>
                <div class="author-info">
                  <div class="cite">
                    <h6 class="name">Florence Themes</h6>
                    <span class="position">/ Multimedia Admin</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="testimonial-four">
                <div class="content">
                  <h3 class="title">
                    Let passion and determination be the guide along the way and
                    develop at your own pace that's comfortable.
                  </h3>
                </div>
                <div class="author-info">
                  <div class="cite">
                    <h6 class="name">Florence Themes</h6>
                    <span class="position">/ Multimedia Admin</span>
                  </div>
                </div>
              </div>
            </div>

                                <div class="swiper-slide">
                                    <div class="testimonial-four">
                                        <div class="content">
                                            <h3 class="title">Let passion and determination be the guide along the way and develop at your own pace that's comfortable.</h3>
                                        </div>
                                        <div class="author-info">
                                            <div class="cite">
                                                <h6 class="name">Florence Themes</h6>
                                                <span class="position">Multimedia Admin</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
            </Carousel> */}
        </div>
      </div>
    </div>
  )
}
