import React from "react";
import Mobilemenu from "./Mobilemenu";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function Footer() {
  const location = useLocation();
  const locationpathname = location.pathname;
  const show = locationpathname == "/launch" ? "none" : "block";
  return (
    <div>
      <div
        className="footer-section section align-cont"
        data-bg-color="#171621"
        style={{ display: show }}
      >
        <div className="container">
          {/* Footer Top Widgets Start */}
          <div className="row">
            {/* Footer Widget Start */}
            <div className="col-xl-6 col-md-5 col-12 max-mb-50">
              <div className="footer-widget light-color">
                <h4 className="footer-widget-title">Address</h4>
                <div className="footer-widget-content">
                  <div className="content">
                    <p>Rushipeetham Charitable Trust.</p>
                    <p>Plot No: 1-9-46, HIG A-40, Dr A S Rao Nagar</p>
                    <p>Hyderabad - 500 062, Telangana, India </p>
                    <p>040-2713 2550</p>
                    <p>
                      <a href="#">info@rushividyabhyasam.org </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* Footer Widget End */}
            {/* Footer Widget Start */}
            <div className="col-xl-3 col-md-4 col-sm-7 col-12 max-mb-50">
              <div className="footer-widget light-color">
                <h4 className="footer-widget-title">Explore</h4>
                <div className="footer-widget-content">
                  <ul className="column-2">
                    <li>
                      <Link to="/">
                        <span className="menu-text">Home</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="aboutus">
                        <span className="menu-text">About us</span>
                      </Link>
                    </li>
                    <li>
                      <Link to="course">Courses</Link>
                    </li>
                    <li>
                      <Link to="contactus">Contact us</Link>
                    </li>
                    <li>
                      <Link to="/login">Sign in</Link>
                    </li>
                    <li>
                      <Link to="/signup">Sign up</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Footer Widget End */}
            {/* Footer Widget Start */}
            <div className="col-xl-3 col-md-3 col-sm-5 col-12 max-mb-50">
              <div className="footer-widget light-color">
                <h4 className="footer-widget-title">Information</h4>
                <div className="footer-widget-content">
                  <ul>
                    <li>
                      <Link to="/privacypolicy">Privacy policy</Link>
                    </li>
                    <li>
                      <Link to="/termsandconditions">Terms of service</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            {/* Footer Widget End */}
          </div>
          {/* Footer Top Widgets End */}
          {/* Footer Copyright Start */}
          <div className="row max-mt-20">
            <div className="col">
              <p className="copyright">
                © 2021 Rushividyabhyasam. <a href="#">All Rights Reserved</a>
              </p>
            </div>
          </div>
          {/* Footer Copyright End */}
        </div>
      </div>

      <a href="#" class="scroll-top" id="scroll-top">
        <i class="arrow-top fal fa-long-arrow-up"></i>
        <i class="arrow-bottom fal fa-long-arrow-up"></i>
      </a>
      <Mobilemenu />
    </div>
  );
}
