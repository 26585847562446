import moment from "moment";
import React, { useEffect } from "react";
import { Navigate, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import Axios from "../utilities/Axios";
import parse from "html-react-parser";
import eventBanner from "../images/event-details.jpg";
import { toast } from "react-toastify";
import swal from "sweetalert";

const EventDetails = () => {
  const { id } = useParams();
  const [eventDetails, setEventDetails] = React.useState({});
  const [speakers, setSpeakers] = React.useState([]);
  const [registerStatus, setRegisterStatus] = React.useState([]);
  const [stuCourseRegisterStatus, setStuCourseRegisterStatus] = React.useState(
    []
  );
  const [timeDuration, settimeDuration] = React.useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });
  React.useEffect(() => {
    callApi();
  }, []);
  const sid = localStorage.getItem("uid") ? localStorage.getItem("uid") : 0;
  const callApi = async () => {
    await Axios.get(`events/get_single_event_for_user/${id}/${sid}`)
      .then((res) => {
        console.log(res?.data?.data[3], "resssss");
        setEventDetails(res?.data?.data[0][0]);
        setSpeakers(res?.data?.data[1]);
        setRegisterStatus(res?.data?.data[2]);
        setStuCourseRegisterStatus(res?.data?.data[3]);
        setInterval(
          () =>
            getTimeUntil(
              moment(res?.data?.data[0][0].start_time)
                .tz(moment.tz.guess())
                .format("YYYY/MM/DD hh:mm A")
            ),
          1000
        );
      })
      .catch((err) => {});
  };

  const date = moment(eventDetails.start_time).format("MMMM D");
  const navigate = useNavigate();

  const handleClick = () => {
    if (!localStorage.getItem("uid")) {
      toast("Please Login to Enroll");
      navigate("/login");
    } else if (stuCourseRegisterStatus.length < 1) {
      toast(
        `please register to ${eventDetails.course_name} course, then you will be able to register for this Event`
      );
    } else {
      const data = {
        user_id: localStorage.getItem("uid"),
        event_id: id,
        course_id: eventDetails.course_id,
      };
      swal({
        title: "Are you sure?",
        text: "Once you click ok, You will be Registered for Event",
        icon: "warning",
        buttons: true,
        dangerMode: true,
      }).then(async (willDelete) => {
        if (willDelete) {
          Axios.post("events/student_register_to_event", data)
            .then((res) => {
              if (res?.data?.status == 200) {
                toast(res?.data?.message, {
                  type: "success",
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
                setRegisterStatus(res?.data?.data);
              } else {
                toast(res?.data?.message, {
                  type: "error",
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                });
              }
            })
            .catch((err) => {
              toast("Something Went Wrong", {
                type: "error",
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
              });
            });
        }
      });
    }
  };

  useEffect(() => {
    //    getTimeUntil("2022/12/10 3:30 pm");
  }, []);

  const getTimeUntil = (deadline) => {
    const time = Date.parse(deadline) - Date.parse(new Date());
    if (time < 0) {
      settimeDuration({ days: 0, hours: 0, minutes: 0, seconds: 0 });
    } else {
      const seconds = Math.floor((time / 1000) % 60);
      const minutes = Math.floor((time / 1000 / 60) % 60);
      const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
      const days = Math.floor(time / (1000 * 60 * 60 * 24));
      settimeDuration({ days, hours, minutes, seconds });
    }
  };

  const leading0 = (num) => {
    return num < 10 ? "0" + num : num;
  };
  console.log(registerStatus.length, "registerStatus.length");

  return (
    <div>
      <div
        className="event-page-banner section overlay section-padding"
        //  data-bg-image="./assets/images/page-banner/event-details.jpg"
        style={{
          backgroundImage: `url(${eventBanner})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center center",
          backgroundSize: "cover",
          overflow: "hidden",
        }}
      >
        <div className="container">
          <div className="col-12">
            <div className="event-hero-content">
              <div className="entry-date"> {date}</div>
              <h1 className="entry-title">{eventDetails?.event_name}</h1>
              <div className="entry-countdown">
                <h1 style={{ color: "#FFFFFF" }}>
                  {leading0(timeDuration.days)} : {leading0(timeDuration.hours)}{" "}
                  : {leading0(timeDuration.minutes)} :{" "}
                  {leading0(timeDuration.seconds)}
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-event-section section section-padding">
        <div className="container">
          <div className="row">
            <div className="col-12 event-regi">
              {registerStatus.length < 1 ? (
                <button
                  className="btn btn-primary btn-hover-secondary"
                  style={{ position: "absolute", right: "0", top: "-56px" }}
                  disabled={
                    registerStatus.length > 0 ||
                    eventDetails.registration_status == "inactive"
                  }
                  onClick={() => {
                    handleClick();
                  }}
                >
                  {registerStatus.length > 0
                    ? "Registered"
                    : eventDetails.registration_status == "inactive"
                    ? "Registrations closed"
                    : "Register"}
                </button>
              ) : (
                <a href={eventDetails.meeting_link} target="_blank">
                  <button
                    className="btn btn-primary btn-hover-secondary"
                    style={{ position: "absolute", right: "0", top: "-56px" }}
                  >
                    Join meeting
                  </button>
                </a>
              )}
              {/* Section Title Start */}
              <div className="text-center course-overview" data-aos="fade-up">
                <h3 className="title heading-color">About The Event</h3>
                <div className="event-entry-meta">
                  <div className="meta-item">
                    <i className="meta-icon fal fa-map-marker-alt" />
                    <span>{eventDetails.course_name}</span>
                  </div>
                  <div className="meta-item">
                    <i className="meta-icon fal fa-calendar" />
                    <span>
                      {moment(eventDetails.start_time)
                        .tz(moment.tz.guess())
                        .format("DD-MMM-YYYY")}
                    </span>
                  </div>
                  <div className="meta-item">
                    <i className="meta-icon fal fa-clock" />
                    <span>{moment(eventDetails.start_time)
                      .tz(moment.tz.guess())
                      .format("hh:mm A z")}-{moment(eventDetails.end_time)
                        .tz(moment.tz.guess())
                        .format("hh:mm A z")}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="event-content align-cont">
                {eventDetails?.description && parse(eventDetails?.description)}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="speaker-section section section-padding-bottom">
        <div className="container section-padding-bottom-50">
          <div className="row">
            <div className="col-12">
              <div
                className="text-center course-overview max-mb-50"
                data-aos="fade-up"
              >
                <h3 className="title heading-color">Our Speakers</h3>
              </div>
            </div>
            <div className="col-md-12" style={{ display: "flex" }}>
              {speakers.length > 0 ? (
                speakers.map((item, index) => (
                  <div
                    className="speaker-slider swiper-container"
                    data-aos="fade-up"
                  >
                    <div className="swiper-wrapper">
                      <div className="swiper-slide">
                        <div className="single-speaker">
                          <div className="image">
                            <img src={item.speaker_image} alt="speaker" />
                          </div>
                          <div className="content">
                            <h6 className="speaker-name">
                              {item.speaker_name}
                            </h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <h6>No Speakers For this event</h6>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
