import React, { useEffect, useState } from "react";
import Axios from "../utilities/Axios";
import { Modal } from "@mui/material";
import { Box } from "@mui/system";
import { toast } from "react-toastify";
const EventSlotBooking = () => {
  const style = {
    backgroundColor: "white",

    border: "1px solid black",
  };
  const [data, setData] = useState({
    name: "",
    email_id: "",
    phone: "",
    child: "",
    adult: "",
    total_amount: "",
    currency: "INR",
    course_type: "Dhurva",
    registration_code: "",
  });
  const [adult, setAdult] = useState(0);
  const [child, setChild] = useState(0);
  const [total, setTotal] = useState(0);
  const [selectedOption, setSelectedOption] = useState("");
  const [payStatus, setPayStatus] = useState(false);
  let num = "";
  let name = "";
  let email = "";
  let reg = "";
  const [numValid, setNumValid] = useState(false);
  const [nameValid, setNameValid] = useState(false);
  const [emailValid, setEmailValid] = useState(false);
  const [adultValid, setAdultValid] = useState(false);
  const [childValid, setChildValid] = useState(false);
  const [regValid, setRegValid] = useState(false);

  const onchangedropdown = (e) => {
    console.log(e.target.value, "vvvvvvvv");
    setSelectedOption(e.target.value);
    setData({ ...data, course_type: e.target.value });
  };

  const onchangechild = (e) => {
    if (e.target.value >= 0) {
      setChildValid(false);
      setChild(e.target.value * 1500);
      setTotal(e.target.value * 1500 + adult);
      setData({
        ...data,
        child: e.target.value,
        total_amount: e.target.value * 1500 + adult,
      });
    } else {
      setChildValid(true);
    }
  };
  const onchangeadult = (e) => {
    if (e.target.value >= 0) {
      setAdultValid(false);
      setAdult(e.target.value * 2500);
      setTotal(e.target.value * 2500 + child);
      setData({
        ...data,
        adult: e.target.value,
        total_amount: e.target.value * 2500 + child,
      });
    } else {
      setAdultValid(true);
    }
  };
  const displayRazorpay = async () => {
    console.log(localStorage.getItem("order"));
    var options = {
      key: "rzp_live_9E17mBGI5uOsWH",

      amount: `${total * 100}`,
      currency: "INR",
      name: "Rushividyabyasam",
      description: "Amount Transaction",
      image: "https://cdn.razorpay.com/logos/Ij8akwhTLP5vsh_large.png",
      order_id: localStorage.getItem("order"),
      prefill: {
        name: data.name,
        email: data.email_id,
        contact: data.phone,
      },
      theme: {
        color: "#ee9200",
      },
      handler: (res) => {
        console.log(res, "resss");
        setPayStatus(true);
        let data1 = {
          razorpay_order_id: res.razorpay_order_id,
          razorpay_payment_id: res.razorpay_payment_id,
          razorpay_signature: res.razorpay_signature,
          id: localStorage.getItem("id"),
          email_id: data.email_id,
          currency: "INR",
          name: data.name,
        };
        console.log(data1, "data111");
        Axios.post("payments/ticket_payment_verify", data1)
          .then((res) => {
            if (res.status == 200) {
              console.log("success");
            }
          })
          .catch((err) => {
            console.log("error");
          });
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
    rzp1.on("payment.failed", function (response) {
      let data = {
        razorpay_order_id: response?.error?.metadata?.order_id,
        razorpay_payment_id: response?.error?.metadata?.payment_id,
      };
      Axios.post("payments/ticket_payment_failure", data)
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    });
  };

  const [isValid, setIsValid] = useState(false);

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const valids = () => {
    if (data.name != "") {
      name = false;
      setNameValid(false);
    } else {
      name = true;
      setNameValid(true);
    }
    if (isValid && data.email_id != "") {
      email = false;
      setEmailValid(false);
    } else {
      email = true;
      setEmailValid(true);
    }
    if (data.phone.length == 10) {
      num = false;
      setNumValid(false);
    } else {
      num = true;
      setNumValid(true);
    }
    if (data.registration_code != "") {
      reg = false;
      setRegValid(false);
    } else {
      reg = true;
      setRegValid(true);
    }
  };
  const onsubmit = async () => {
    console.log(data, "dddddd");
    valids();

    if (num == false && email == false && name == false && reg == false) {
      await Axios.post("payments/create_ticket", data)
        .then((responseData) => {
          // console.log(responseData, "respdata");
          // console.log(responseData.data.order, "resporder");
          localStorage.setItem("order", responseData.data.order);
          localStorage.setItem("id", responseData.data.id);
          if (responseData.status == 200) {
            console.log("yes you hit the data", responseData);
            displayRazorpay();
          }
        })
        .catch((err) => {
          console.log("err");
        });
    }
  };
  const styles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "60%",
    height: "200px",
    backgroundColor: "white",
    bgcolor: "white",
    borderRadius: 10,
    boxShadow: 24,
    p: 4,
  };

  return (
    <>
      <div className="login-register-section section section-padding-bottom section-padding-top">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="row">
                <div className="offset-xl-3 col-xl-6 offset-xl-1 col-lg-6">
                  {/* Register Form Start */}
                  <div className="login-form-wrapper mt-sm-50 mt-xs-50">
                    <div>
                      <center>
                        <h3 className="title">Event Slot Booking</h3>
                      </center>
                    </div>

                    <form className="register-form align-cont" action="#">
                      <div className="single-input mb-30">
                        <h6>Course Type</h6>
                        <select
                          className="course-dd"
                          placeholder="Druva Jnanam"
                          onChange={onchangedropdown}
                          disabled
                        >
                          <option value="Druva jnanam">Druva jnanam</option>
                          <option value="Druva jnanam" disabled>
                            Druva jnanam
                          </option>
                          <option value="Druva jnanam">Druva jnanam</option>
                        </select>
                      </div>
                      <div className="f-l-adjust">
                        <div className="single-input mb-30">
                          {/* <label htmlFor="usernameOne">First Name</label> */}
                          <h6>Name</h6>
                          <input
                            type="text"
                            placeholder="Name"
                            onChange={(e) => {
                              setData({ ...data, name: e.target.value });
                            }}
                            required
                          />
                          <p className="pwd-err">
                            {nameValid ? "Required" : ""}
                          </p>
                        </div>
                        <div className="single-input mb-30">
                          {/* <label htmlFor="usernameOne">Last Name</label> */}
                          <h6>Phone</h6>
                          <input
                            type="text"
                            placeholder="Phone"
                            onChange={(e) => {
                              setData({ ...data, phone: e.target.value });
                            }}
                            required
                          />
                          <p className="pwd-err">
                            {numValid ? "Enter valid mobile number" : ""}
                          </p>
                        </div>
                      </div>
                      <div className="single-input mb-30">
                        {/* <label htmlFor="email">Email</label> */}
                        <h6>Email</h6>
                        <input
                          type="email"
                          placeholder="Email"
                          className="inputfield"
                          // style={style}
                          onChange={(e) => {
                            setData({ ...data, email_id: e.target.value });
                            setIsValid(validateEmail(data.email_id));
                          }}
                        ></input>
                        <p className="pwd-err">
                          {emailValid ? "Please enter valid Email" : ""}
                        </p>
                      </div>
                      <div className="single-input mb-30">
                        {/* <label htmlFor="email">Email</label> */}
                        <h6>Re-treate registration code</h6>
                        <input
                          type="text"
                          placeholder="Code"
                          className="inputfield"
                          // style={style}
                          onChange={(e) => {
                            setData({
                              ...data,
                              registration_code: e.target.value,
                            });
                          }}
                        ></input>
                        <p className="pwd-err">
                          {regValid ? "Field is Mandatory" : ""}
                        </p>
                      </div>
                      <div className="f-l-adjust">
                        <div className="single-input mb-30">
                          {/* <label htmlFor="usernameOne">First Name</label> */}
                          <h6>
                            Adult
                            <span style={{ color: "red" }}> (Rs.2500/-)</span>
                          </h6>

                          <input
                            type="number"
                            placeholder="0"
                            onChange={onchangeadult}
                          />
                          <p style={{ color: "red" }}>
                            {adultValid ? "please enter correct number" : ""}
                          </p>
                        </div>
                        <div className="single-input mb-30">
                          {/* <label htmlFor="usernameOne">Last Name</label> */}
                          <h6>
                            Child{" "}
                            <span style={{ color: "red" }}>(Rs.1500/-)</span>
                          </h6>
                          <input
                            type="number"
                            onChange={onchangechild}
                            placeholder="0"
                          />
                          <p style={{ color: "red" }}>
                            {childValid ? "please enter correct number" : ""}
                          </p>
                        </div>
                      </div>
                      <div className="single-input mb-30">
                        <h6 style={{ color: "green" }}>Total Amount</h6>
                        <div
                          style={{
                            border: "1px solid gray",
                            borderRadius: "5px",
                            height: "50px",
                          }}
                        >
                          {total == 0 ? (
                            <h6
                              style={{ marginTop: "12px", marginLeft: "15px" }}
                            >
                              Total Amount
                            </h6>
                          ) : (
                            <h6
                              style={{ marginTop: "12px", marginLeft: "15px" }}
                            >
                              {child + adult}
                            </h6>
                          )}
                        </div>
                      </div>

                      <div className="single-input">
                        <button
                          type="button"
                          className="btn btn-primary btn-hover-secondary btn-width-100"
                          onClick={onsubmit}
                          disabled={total == 0 ? "disabled" : ""}
                        >
                          Pay Now
                        </button>
                      </div>
                    </form>
                  </div>
                  {/* Register Form End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {payStatus ? (
        <Modal
          open={payStatus}
          onClose={() => {
            setPayStatus(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box style={styles}>
            <center>
              <div className="container">
                <h1 style={{ textAlign: "center", marginTop: "30px" }}>
                  Payment successfull
                </h1>
                <button
                  style={{ textAlign: "center", marginTop: "30px" }}
                  type="button"
                  className="btn btn-primary btn-hover-secondary btn-width-30"
                  onClick={() => {
                    setPayStatus(false);
                    // localStorage.removeItem("order");
                    // localStorage.removeItem("razorpay_signature");
                    // localStorage.removeItem("razorpay_payment_id");
                    // localStorage.removeItem("razorpay_order_id");
                  }}
                >
                  Close
                </button>
              </div>
            </center>
          </Box>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};
export default EventSlotBooking;
