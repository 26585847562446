import "./App.css";
import RouterPage from "./pages/RouterPage";
import { ToastContainer } from "react-toastify";
import { useEffect } from "react";
import EventSlotBooking from "./components/EventSlotBooking";
function App() {
  // return (
  //   <>
  //     <EventSlotBooking />
  //   </>
  // );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="App">
      <ToastContainer />
      <RouterPage />
    </div>
  );
}

export default App;
