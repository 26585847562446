import React from "react";
import glimpseImg from ".././images/about/about-me/video-about-us-2.jpg";

const Glimpsecard = ({ glimpsArray }) => {
  // console.log(glimpsArray, "glimpcard");
  return (
    <div>
      <div className="related-courses-section section sec-padding">
        <div className="container">
          {/* Section Title Start */}
          <div className="section-title text-center mb-35" data-aos="fade-up">
            <h3 className="title">Glimpse​</h3>
          </div>
          <div className="row">
            <div id="accordion" className="g-sec">
              {glimpsArray.length > 0
                ? glimpsArray.map((item, index) => {
                    return (
                      <div className="col-md-4 mg-b-20 g-card">
                        <div
                          class="card course g-info"
                          data-toggle="collapse"
                          href={"#collapseOnes" + item.glimpse_id}
                        >
                          <div className="glimpse-card-info">
                            <img
                              src={item.glimpse_image}
                              alt="logo"
                              className="glimpse-img"
                            />
                            <div className="g-cont">
                              <h5>{item.glimpse_name}</h5>
                              <p>
                                {item.glimpse_description?.length > 42
                                  ? item.glimpse_description?.substring(0, 42) +
                                    "..."
                                  : item.glimpse_description}
                              </p>
                            </div>
                          </div>
                          <div
                            id={"collapseOnes" + item.glimpse_id}
                            class="collapse"
                            data-parent="#accordion"
                          >
                            <div className="exp-cont">
                              {item.glimpse_description}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                : ""}
            </div>
          </div>
          {/* Section Title End */}
        </div>
      </div>
    </div>
  );
};

export default Glimpsecard;
