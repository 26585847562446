import React from 'react'
import { useNavigate } from "react-router-dom";

const EventsCard = ({
    name,
    image,
    course,
    id
}) => {
   
    const navigate = useNavigate();
    const imageClicked = () => {
      
       navigate(`/eventDetails/${id}`);
         
      };
    return (
        <div>
          <div className="col max-mb-30" data-aos="fade-up">
            <div className="course">
              <div className="thumbnail">
                {/* <Link to={`/coursedetails/${id}`} className="image"> */}
    
                <img
                  // src="assets/images/courses/370/course-1.jpg"
                  src={image}
                  onClick={() => imageClicked()}
                  alt="Coursemage"
                  className="c-s"
                  style={{ cursor: "pointer" }}
                />
                {/* </Link> */}
              </div>
              <div className="info regi-main">
                 {/* //for other country */}
                
                
    
                <span className="price title-tra">
                  {/* $40<span>.00</span> */}
                  <a
                    //to={`/coursedetails/${id}`}
                    className="image"
                    // onClick={() => imageClicked(id, price, status)}
                  >
                    {name}
                  </a>
                </span>
                <h4 className="cour-lab">
                  {" "}
                  {course}
                </h4>
                {/* {price && status != "coming_soon" && (
                 <h3 className="title">
                 {price} / {id == 64397396 ? 'person' : duration}{' '}
               </h3>
                )}
     */}
                {/* <ul className="meta title-tra">
                  <li>
                    <i className="far fa-file-alt" />
                    {lessons} Lessons
                  </li>
                  <li>
                    <i className="far fa-clock" />
                    {duration}
                  </li>
                </ul> */}
              </div>
            </div>
          </div>
        </div>
      );
}

export default EventsCard