import React from "react";
import { Link, NavLink } from "react-router-dom";
import Course from "./Course";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
export default function Header() {
  let userid = localStorage.getItem("uid");

  const navigate = useNavigate();
  const location = useLocation();
  const locationpathname = location.pathname;
  const show = locationpathname == "/launch" ? "none" : "block";
  console.log("header", location.pathname);
  const Logout = () => {
    localStorage.removeItem("uid");
    navigate("/");
  };

  return (
    <div>
      {/* Header Section Start */}
      <div
        className="header-section header-fluid sticky-header section"
        style={{ display: show }}
      >
        <div className="header-inner">
          <div className="container position-relative">
            <div className="row justify-content-between align-items-center">
              {/* Header Logo Start */}
              <div className="col-xl-3 col-auto">
                <div className="header-logo">
                  <Link to="/">
                    <img
                      className="dark-logo"
                      src="http://www.rushividyabhyasam.org/assets/images/logo/logo-web-v5.png"
                      alt="Learts Logo"
                    />
                    {/* <img
                      className="light-logo"
                      src="assets/images/logo/light-logo.png"
                      alt="Learts Logo"
                    /> */}
                  </Link>
                </div>
              </div>
              {/* Header Logo End */}
              {/* Header Main Menu Start */}
              <div className="col d-none d-xl-block position-static">
                <nav className="site-main-menu m-menu-ali">
                  <ul>
                    <li className="position-static">
                      <NavLink
                        to="/"
                        className={(isActive) => (!isActive ? " " : "active")}
                      >
                        <span className="menu-text">Home</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/aboutus"
                        className={(isActive) => (!isActive ? " " : "active")}
                      >
                        <span className="menu-text">About us</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/course"
                        className={(isActive) => (!isActive ? " " : "active")}
                      >
                        <span className="menu-text">Courses</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/events"
                        className={(isActive) => (!isActive ? " " : "active")}
                      >
                        <span className="menu-text">Events</span>
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to="/contactus"
                        className={(isActive) => (!isActive ? "" : "active")}
                      >
                        <span className="menu-text">Contact us</span>
                      </NavLink>
                    </li>
                  </ul>
                </nav>
              </div>
              {/* Header Main Menu End */}
              {/* Header Right Start */}
              <div className="col-xl-3 col-auto">
                <div className="header-right">
                  <div className="inner">
                    {/* Header Login Start */}
                    {/* <div className="header-login">
                      <a href="profile.html">
                        <i className="far fa-user-circle" />
                      </a>
                    </div> */}

                    <nav className="site-main-menu">
                      <ul>
                        {!userid ? (
                          <React.Fragment>
                            <li>
                              <Link to="/login">
                                <span className="menu-text">Sign in</span>
                              </Link>
                            </li>
                            <li>
                              <Link to="/signup">
                                <span className="menu-text">Sign up</span>
                              </Link>
                            </li>
                          </React.Fragment>
                        ) : null}
                        {localStorage.getItem("uid") ? (
                          <li className="has-children w-n-n">
                            <p className="welcome-name">
                              <span>Namaste</span>{" "}
                              {localStorage.getItem("uname")}
                            </p>
                            <a href="#">
                              <img
                                src="http://www.rushividyabhyasam.org/assets/images/testimonial/140/avator.png"
                                alt
                                className="pro-icn"
                              />
                            </a>
                            <span className="menu-toggle">
                              <i className="far fa-angle-down" />
                            </span>
                            <ul className="sub-menu">
                              <li>
                                {console.log(localStorage.getItem("uid"))}

                                <>
                                  <Link to="/myaccount">
                                    <span className="menu-text">
                                      My Account
                                    </span>
                                  </Link>
                                  <a onClick={() => Logout()}>
                                    <span className="menu-text">Logout</span>
                                  </a>
                                </>
                              </li>
                            </ul>
                          </li>
                        ) : (
                          ""
                        )}
                      </ul>
                    </nav>

                    {/* Header Login End */}

                    {/* Header Mobile Menu Toggle Start */}
                    <div className="header-mobile-menu-toggle d-xl-none ml-sm-2">
                      <button className="toggle">
                        <i className="icon-top" />
                        <i className="icon-middle" />
                        <i className="icon-bottom" />
                      </button>
                    </div>
                    {/* Header Mobile Menu Toggle End */}
                  </div>
                </div>
              </div>
              {/* Header Right End */}
            </div>
          </div>
        </div>
      </div>
      {/* Header Section End */}
    </div>
  );
}
