import React from "react";

import { useNavigate } from "react-router-dom";
const Relatedcourses = ({ data }) => {
  const navigate = useNavigate();
  const imageClicked = (id, price, status) => {
    if (status !== "coming_soon") {
      navigate(`/coursedetails/${id}`);
      localStorage.setItem("price", price);
    }
  };
  let reldata = data;

  return (
    <div>
      {reldata.length > 0 ? (
        <div className="related-courses-section section sec-padding">
          <div className="container">
            {/* Section Title Start */}
            <div className="section-title text-center mb-35" data-aos="fade-up">
              <h3 className="title">Other Courses​</h3>
            </div>
            {/* Section Title End */}
            {/* Courses Wrapper Start */}
            <div className="courses-slider swiper-container" data-aos="fade-up">
              <div className="row">
                {/* Course Start */}
                {/* {console.log(reldata, "testdaat")} */}
                {reldata?.map((list, index) => {
                  if (index <= 2)
                    return (
                      <div
                        key={index}
                        className="col-md-4 swiper-slide1 mb-30"
                        style={{ display: "flex" }}
                      >
                        <div className="course box-shadow">
                          <div className="thumbnail">
                            <div
                              style={{ cursor: "pointer" }}
                              // onClick={() => imageClicked(list.id, list.price)}
                              className="image"
                            >
                              <img
                                style={{
                                  objectFit: "cover",
                                  width: "370px",
                                  height: "219px",
                                }}
                                //   src="assets/images/courses/370/course-1.jpg"
                                src={list.image}
                                onClick={() =>
                                  imageClicked(list.id, list.price, list.status)
                                }
                                alt="Coursemage"
                              />
                            </div>
                          </div>
                          <div className="info regi-main">
                            {list.register_status == "yes" ? (
                              <span className="regi-btn">Registered</span>
                            ) : (
                              ""
                            )}
                            {list.status === "coming_soon" ? (
                              <span className="regi-btn c-s-c">Coming soon</span>
                            ) : (
                              ""
                            )}
                            <span className="price title-tra">
                              <p
                                onClick={() =>
                                  imageClicked(list.id, list.price, list.status)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                {list.course_name}
                              </p>
                            </span>
                            <h6 className="cour-lab">
                              {list.type !== null
                                ? list.type != "" && "( " + list.type + " )"
                                : null}
                            </h6>{console.log("list", list)}
                            {list?.price ? (
                              <>
                                {
                                  list.price && list.status != "coming_soon" && (
                                    <h3 className="title">{list.price} / {list.id == 64397396 ? 'person' : list.course_duration}</h3>
                                  )
                                }
                                < ul className="meta">
                                  <li className=" title-tra">
                                    <i className="far fa-file-alt" />
                                    {list.lessons} Lessons
                                  </li>
                                  <li className=" title-tra">
                                    <i className="far fa-clock" />
                                    {list.course_duration}
                                  </li>
                                </ul></>) : (


                              <>
                                {/* <div className="c-content"> */}
                                {/* <div>Check the price</div> */}
                                <div className="c-values">
                                  <div className="price-link"
                                    onClick={() => navigate("/login")}
                                    style={{ color: "blue" }}
                                  >
                                    Sign in for price details
                                  </div>

                                </div>
                                {/* </div> */}
                                < ul className="meta">
                                  <li className=" title-tra">
                                    <i className="far fa-file-alt" />
                                    {list.lessons} Lessons
                                  </li>
                                  <li className=" title-tra">
                                    <i className="far fa-clock" />
                                    {list.course_duration}
                                  </li>
                                </ul></>
                            )


                            }
                          </div>
                        </div>
                      </div>
                    );
                })}
              </div>
              <div className="swiper-pagination" />
            </div>
            {/* Courses Wrapper End */}
          </div>
        </div>
      ) : (
        <p>loading....</p>
      )}
    </div>
  );
};

export default Relatedcourses;
