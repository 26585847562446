import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import Axios from '../utilities/Axios'
import { Validator } from '../utilities/validation'
import { toast } from 'react-toastify'
import CryptoJS from 'crypto-js'
const Login = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  const [username, setUsername] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [waitstatus, setWaitstatus] = React.useState(false)
  const [checked, setChecked] = React.useState(false)
  const [showpwd, setShowpwd] = React.useState(false)
  const [, forceUpdate] = React.useState(false)
  let validator = Validator()
  const navigate = useNavigate()
  const handleSubmit = (e) => {
    e.preventDefault()
    if (validator.current.allValid()) {
      // alert("from 16");
      callApi()
    } else {
      forceUpdate(true)
      validator.current.showMessages()
    }
  }

  React.useEffect(() => {
    const data = document.cookie.split('=')
    console.log(document.cookie, 'document.cookie')
    if (data[2]) {
      setChecked(true)
      setUsername(data[0])
      setPassword(data[1])
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleCheckbox = () => {
    setChecked(!checked)
  }
  const showpassword = () => {
    setShowpwd(!showpwd)
  }
  const callApi = async () => {
    setWaitstatus(true)
    const new_pass = CryptoJS.AES.encrypt(password, 'rushi').toString()
    let data = {
      email_id: `${username}`,
      password: `${new_pass}`,
      flag: 'student',
    }
    // console.log(data);
    Axios.post('/user_details/login', data)
      .then((res) => {
        // console.log(res.data);
        setWaitstatus(false)
        if (res.data.status === 200) {
          setWaitstatus(false)
          localStorage.setItem('uid', JSON.stringify(res && res.data.user_id))
          localStorage.setItem('role', res.data.role)
          localStorage.setItem('uname', res.data.full_name)
          localStorage.setItem('phone_number', res.data.phone_number)
          localStorage.setItem('email_id', res.data.email_id)
          localStorage.setItem('city', res.data.city)

          if (checked) {
            const dd = 'Sun, 20 Aug 2000 12:00:00 UTC'
            // d.setTime((d.getTime() - 100) * 24 * 60 * 60 * 1000);
            let expires = 'expires=' + dd
            document.cookie =
              username +
              '=' +
              password +
              '=' +
              checked +
              ';' +
              expires +
              ';path=/'
            const d = new Date()
            d.setTime(d.getTime() + 5 * 24 * 60 * 60 * 1000)
            let expiress = 'expires=' + d.toUTCString()
            document.cookie =
              username +
              '=' +
              password +
              '=' +
              checked +
              ';' +
              expiress +
              ';path=/'
          } else {
            //clear cookie
            const d = 'Sun, 20 Aug 2000 12:00:00 UTC'
            // d.setTime((d.getTime() - 100) * 24 * 60 * 60 * 1000);
            let expires = 'expires=' + d
            document.cookie =
              username +
              '=' +
              password +
              '=' +
              checked +
              ';' +
              expires +
              ';path=/'
          }
          localStorage.setItem('token', res && res.data.token)
          if (res.data.mainrole === 'student') {
           
            if (!res.data.gender || !res.data.region) {
              window.localStorage.setItem('isUpdated', false)
              navigate('/myaccount')
              return
            } else {
              window.localStorage.setItem('isUpdated', true)
              navigate('/')
            }
          } else {
            setWaitstatus(false)
            toast('Invalid credentials', {
              position: 'top-center',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              theme: 'dark',
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            })
          }
        }
      })
      .catch((err) => {
        // console.log(err);
        setWaitstatus(false)
        toast('Invalid credentials', {
          position: 'top-center',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          theme: 'dark',
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        })
      })
  }

  return (
    <div>
      <div>
        {/* Page Title Section Start */}
        <div className="page-title-section section auth-ali">
          <div className="page-breadcrumb">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">Sign In</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Page Title Section End */}
        {/*Login Register section start*/}
        <div className="login-register-section section section-padding-bottom">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div className=" offset-xl-3 col-xl-6 col-lg-6">
                    {/* Login Form Start */}
                    <div className="login-form-wrapper">
                      {/* <h3 className="title">Login</h3> */}
                      <img
                        src="http://www.rushividyabhyasam.org/assets/images/logo/rushividyahyasam-small-logo.png"
                        alt="Learts Logo"
                        className="auth-logo"
                      />
                      <form className="login-form align-cont" action="#">
                        <div className="single-input mb-30">
                          {/* <label htmlFor="username"> Email</label> */}
                          <input
                            type="text"
                            id="username"
                            name="username"
                            placeholder=" Email"
                            value={username}
                            onChange={(e) => setUsername(e.target.value)}
                          />
                          <span className="pwd-err">
                            {validator.current.message(
                              'Email',
                              username,
                              'required|email',
                            )}
                          </span>
                        </div>
                        <div className="single-input mb-30">
                          {/* <label htmlFor="password">Password</label> */}
                          <input
                            type={showpwd ? 'text' : 'password'}
                            id="password"
                            name="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                          <i
                            className={`far fa-eye${!showpwd ? '' : '-slash'}`}
                            id="togglePassword"
                            style={{ marginLeft: '-30px', cursor: 'pointer' }}
                            onClick={() => showpassword()}
                          />

                          <span className="pwd-err">
                            {validator.current.message(
                              'password',
                              password,
                              'required',
                            )}
                          </span>
                        </div>
                        <div className="single-input mb-30">
                          <div className="row">
                            <div className="col-sm-6 remember-me-wrap">
                              <div className="checkbox-input">
                                <input
                                  type="checkbox"
                                  name="login-form-remember"
                                  id="login-form-remember"
                                  checked={checked}
                                  onChange={() => handleCheckbox()}
                                />
                                <label htmlFor="login-form-remember">
                                  Remember me
                                </label>
                              </div>
                            </div>
                            <div className="col-sm-6 lost-your-password-wrap">
                              <p>
                                <a
                                  href="/forgotpassword"
                                  className="lost-your-password"
                                >
                                  Forgot Password?
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="single-input">
                          <button
                            className="btn btn-primary btn-hover-secondary btn-width-100"
                            onClick={(e) => handleSubmit(e)}
                          >
                            {waitstatus ? 'Please Wait..' : 'Login '}
                          </button>
                        </div>
                        <p className="d-signup">
                          Don't have an account ?{' '}
                          <Link to="/signup">Signup now!</Link>
                        </p>
                      </form>
                    </div>
                    {/* Login Form End */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*Login Register section end*/}
      </div>
    </div>
  )
}

export default Login
