import React from "react";
import Axios from '../utilities/Axios'
import EventsCard from "../components/EventsCard";
import { Link } from 'react-router-dom'
import logo from '../images/Spinner.gif'

const Events = () => {

  const [events,setEvents]=React.useState([])
  const [loading, setLoading] = React.useState(false)

React.useEffect(()=>{
callApi();
},[])

const callApi = async()=>{
  setLoading(true)
  await Axios.get('events/get_all_events_user')
  .then((res) => {
    
    console.log(res?.data?.data,"resssss")
    setEvents(res?.data?.data)
    setLoading(false)
    
  })
  .catch((err) => {
    console.log(err)
  })
}
if (loading) {
  return (
    <div
      className="loader"
      style={{
        minHeight: '90vh',
        minWidth: '100vw',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <img
        src={logo}
        alt="loader"
        style={{
          minHeight: '10vh',
        }}
      />
    </div>
  )
}




return (
  <div>
    <div id="page">
      {/* Page Title Section Start */}
      <div className="page-title-section section">
        <div className="page-title">
          <div className="container">
            <h1 className="title">All Events</h1>
          </div>
        </div>
        <div className="page-breadcrumb">
          <div className="container">
            <ul className="breadcrumb">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li className="current">All Events</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section section-padding-bottom">
        <div className="container">
          {/* Course Top Bar Start */}
          <div className="row justify-content-between align-items-center max-mb-20">
            <div className="col-sm-auto col-12 max-mb-10">
              {events?.length > 0 ? (
                <p className="result-count">
                  We found <span>{events.length}</span> Events available for
                  you
                </p>
              ) : (
                <p className="result-count">No Data found</p>
              )}
            </div>
            {/* <div className="col-sm-auto col-12 max-mb-10">
              <select className="sort-by">
                <option selected="selected">Default</option>
                <option value="popularity">Popularity</option>
                <option value="date">Latest</option>
                <option value="price">Price: low to high</option>
                <option value="price-desc">Price: high to low</option>
              </select>
            </div> */}
          </div>
          {/* Course Top Bar End */}
          {/* Courses Wrapper Start */}
          <div className="row row-cols-lg-3 row-cols-md-2 row-cols-1 max-mb-n30">
            {events?.map((list) => (
              
              <EventsCard
                name={list.event_name}
                image={list.image_url}
                course={list.course_name}
                id={list.event_id}
                
              />
              
            ))}
          </div>
          {/* Courses Wrapper End */}
          <div className="row max-mt-50">
            {/* <div className="col text-center">
              <a
                href="JavaScript:Void(0);"
                className="btn btn-outline-primary load-more-btn"
              >
                Load Mores <i className="fal fa-redo ms-3" />
              </a> 
            </div> */}
          </div>
        </div>
      </div>
      {/* Course Section End */}
      {/* </div> */}
    </div>
  </div>
)
};

export default Events;
