import React from "react";
import { Link } from "react-router-dom";
const Aboutus = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <div>
        <div
          className="page-title-section section section-padding-top-0"
          data-bg-color="#f5f5f5"
        >
          <div className="page-breadcrumb position-static">
            <div className="container">
              <ul className="breadcrumb">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li className="current">About us</li>
              </ul>
            </div>
          </div>
        </div>
        {/* Page Title Section End */}
        {/* Testimonial Section Start */}
        <div className="testimonial-section section" data-bg-color="#f5f5f5">
          <div className="container animation-shape">
            {/* <div className="row">
              <div className="col-lg-12">
                <div
                  className="testimonial-slider-three swiper-container"
                  data-aos="fade-up"
                >
                  <div className="swiper-wrapper">
                    <div className="swiper-slide">
                      <div className="testimonial-four">
                        <div className="content">
                          <h3 className="title">
                            Let passion and determination be the guide along the
                            way and develop at your own pace that's comfortable.
                          </h3>
                        </div>
                        <div className="author-info">
                          <div className="cite">
                            <h6 className="name">Rushividyabhyasam</h6>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}
            {/* Animation Shape Start */}
            <div className="shape shape-1 scene">
              <span data-depth={4}>shape 1</span>
            </div>
            <div className="shape shape-2 scene">
              <span data-depth={4} />
            </div>
            <div className="shape shape-3 scene">
              <span data-depth={4} />
            </div>
            <div className="shape shape-4 scene">
              <span data-depth={4} />
            </div>
            <div className="shape shape-5 scene">
              <span data-depth={4} />
            </div>
            <div className="shape shape-6 scene">
              <span data-depth={4} />
            </div>
            {/* Animation Shape End */}
          </div>
        </div>
        {/* Testimonial Section End */}
        {/* Video Section Start */}
        <div
          id="video-section-1"
          className="section position-relative"
          data-bg-color="#f5f5f5"
        >
          <div className="container">
            {/* About Me Video Wrapper Start */}
            <div className="about-me-video-wrapper">
              {/* About Me Video Start */}
              <a
                href="https://www.youtube.com/watch?v=BsolkMHI0Ok"
                className="about-me-video video-popup"
                data-aos="fade-up"
              >
                <img
                  className="image"
                  src="assets/images/about/about-me/video-poster.jpg"
                  alt
                />
                <img
                  className="icon"
                  src="assets/images/icons/icon-youtube-play.png"
                  alt
                />
              </a>
              {/* About Me Video End */}
              {/* Animation Shape Start */}
              {/* <div className="shape shape-1 scene">
                <span data-depth={3}>
                  <img
                    src="assets/images/shape-animation/shape-2.svg"
                    alt
                    className="svgInject"
                  />
                </span>
              </div> */}
              <div className="shape shape-2 scene">
                <span data-depth={-3}>
                  <img src="assets/images/shape-animation/shape-3.png" alt />
                </span>
              </div>
              <div className="shape shape-3 scene">
                <span data-depth={4}>shape 3</span>
              </div>
              <div className="shape shape-4 scene">
                <span data-depth={4}>
                  <img src="assets/images/shape-animation/shape-1.png" alt />
                </span>
              </div>
              {/* Animation Shape End */}
            </div>
            {/* About Me Video Wrapper End */}
          </div>
          {/* Section Bottom Shape Start */}
          <div className="section-bottom-shape d-md-block d-none">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              height={100}
            >
              <path
                className="elementor-shape-fill"
                d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"
              />
            </svg>
          </div>
          {/* Section Bottom Shape End */}
        </div>
        {/* Video Section End */}

        <div class="newsletter-section section section-padding position-relative">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <div class="newsletter-content">
                  <h2 class="title">
                    About <span>Brahmasri Samavedam Shanmukha Sarma</span> garu
                  </h2>
                  <p>
                    Brahmasri Samavedam Shanumkha Sarma garu was born to Smt
                    Ramanamma garu and Sri Rama Murthy Sarma garu on May 16,
                    1967 in Asika, Orissa, India. He graduated in Economics with
                    honors from Berhampur University. Born and brought up in an
                    illustrious scholarly family, under the direct tutelage of
                    his father as first Guru, Sri Sarma garu became proficient
                    in various Vedic literary works in both Sanskrit and his
                    mother tongue Telugu.
                  </p>
                  <p>
                    His discourses spread across a wide range of topics from the
                    Puranas, Itihasas, and Kavyas to erudite Vedantic treatises
                    such as ‘Prastana Trayam’ (Upanishads, Brahmasutras and
                    Bhagavad Gita) and Shanmatas (Saiva, Vaishnava, Sakteya,
                    Ganapatya, Skanda and Saura) expounding the profound
                    concepts of Dharma, Worship, Philosophy, Religion, Agama
                    etc. His literary works include ‘Sri Rudra Bhashyam’, ‘Sri
                    Vishnu Vidya’, ‘Yesha Dharma Sanatanaha’, ‘Sri Lalita
                    Vidya’, ‘Mugguru Sundarula Katha’ etc. in addition to
                    numerous articles in various magazines. The expressions in
                    some of his mellifluous poetical works such as ‘Siva Padam’,
                    ‘Sri Neelakantheswara’, ‘Sri Ramachandra Prabho’ etc.
                    reflect his profound and sincere bhakti.
                  </p>
                  <p>
                    During this saga, he received countless awards & accolades
                    and motivated innumerable followers all over the world.
                    These honors include{" "}
                    <b>
                      “Sri Shankara Digvijaya Pravachana Chatura” , “Bharathi
                      Thyagaraja Samman”
                    </b>{" "}
                    and titles such as{" "}
                    <b>
                      ‘Samanvaya Saraswati’, ‘Arsha Dharmopanyasa Kesari’,
                      ‘Vagdevi Varaputra’, ‘Arsha Parampara Samposhaka’, ‘Siva
                      Tattva Sudha Nidhi’
                    </b>{" "}
                    are few accolades from an exhaustive but impressive list.
                  </p>
                  <p>
                    It is no exaggeration to say that he is successful in
                    bringing Sanatana Dharma renaissance and ushering in a new
                    era. He continues to inspire youth across the world as a
                    role model and stands towering as a beacon light to all
                    spiritual aspirants.
                  </p>
                  <br />

                  <h2 class="title">
                    About <span>Rushipeetham</span>
                  </h2>

                  <p>
                    Rushipeetham’ means ‘Bharatha Bhumi’, the land of seers and
                    sages. Eons ago, our rishis perceived the eternal knowledge
                    of the Universe through their power of penance which
                    otherwise cannot be perceived by the physical senses. They
                    brought forth secular and spiritual knowledge, wishing for
                    universal well-being and happiness.
                  </p>
                  <p>
                    Rushipeetham magazine came into being shouldering the
                    Herculean task of bringing forth inherent eternal values,
                    knowledge and hidden pearls of wisdom in our Sanatana Dharma
                    and accepting it as a humble duty and responsibility towards
                    a much-needed cultural renaissance. Rushipeetham, is the
                    brainchild of the renowned literary luminary, reputed
                    scholar and philosopher Bramhasri Samavedam Shanmukha
                    Sharma. His undeterred and passionate devotion towards the
                    Paramatma and strong patriotism towards the nation found a
                    voice of expression in Rushipeetham. The magazine grew into
                    being bilingual to meet contemporary needs of today’s youth
                    and to increase outreach but without compromising on its
                    rich content.
                  </p>
                  <br />
                  <h2 class="title">
                    About <span>Rushividyabhyasam</span>
                  </h2>
                  <p>
                    Brahmasri Samavedam Shanmukha Sarma garu has influenced
                    innumerable people across the globe and created a positive
                    impact in their lives with his discourses and writings. To
                    aid serious aspirants to continue their spiritual journey
                    from Shravanam into manam and nidhi dhyasa and also pass
                    down the same to the next generation,Sri Guruvu Garu has
                    blessed the commencement of RushiVidyabhyasam, an online
                    university. The university hosts all educational programs
                    being run under the umbrella of Rushipeetam to cater to
                    audiences across various age groups and varied subjects.
                  </p>
                  <p>
                    - Learn Bhagavad Gita through Rushipeetham' are classes for
                    adults to recite The Gita accurately while being cognizant
                    of the meaning.
                    <br />- Guru Jnanam is a study group that focuses on the
                    contents of Sri Guruvu Gari pravachanams as a life course.
                    The aim is to deeply study the purpose of life and practical
                    methods to reach the goal.
                    <br />- Dhruva Jnanam was started with a single goal in
                    mind- “Success with Character”. It is a value-based program
                    for children ages five and above that aids children in
                    amalgamating the morals, customs, traditions, and culture in
                    Sanatana Dharma into their daily lives with courage,
                    confidence and clarity.
                  </p>

                  <div class="shape shape-2 scene">
                    <span data-depth="4">
                      <img
                        src="assets/images/shape-animation/nwesletter-shape-1.png"
                        alt=""
                      />
                    </span>
                  </div>
                  <div class="shape shape-3 scene">
                    <span data-depth="4">
                      <img
                        src="assets/images/shape-animation/nwesletter-shape-2.png"
                        alt=""
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Teaching Section Start */}
        {/* <div
          className="teaching-section section section-padding-top section-padding-bottom-70"
          data-bg-color="#f5f5f5"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8">
                <div className="testimonial-area mb-sm-30 mb-xs-30">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="static-testimonial">
                        <div className="testimonial-image c-a-pic">
                          <img
                            src="assets/images/testimonial/140/avator.png"
                            alt
                          />
                        </div>
                        <div className="testimonial-content">
                          <p>
                            I love their flexibility. Even when my request is
                            too complicated to handle. they could still suggest
                            something useful for me.{" "}
                          </p>
                        </div>
                        <div className="author-info">
                          <div className="cite">
                            <h6 className="name">Viswanath Sharma Vundi</h6>
                            <span className="position">
                              Course Admin / Jagadguru
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="testimonial-modern-heading">
                        <h2 className="title">
                          "Best out of the best in the online coaching field
                          ..."
                        </h2>
                      </div>
                    </div>
                    <div className="col-md-6 pr-100 pr-md-15 pr-sm-15 pr-xs-15">
                      <div className="static-testimonial">
                        <div className="testimonial-image stu-img">
                          <img
                            src="assets/images/testimonial/100/avator.png"
                            alt
                          />
                        </div>
                        <div className="testimonial-content">
                          <p>
                            Excellent guiding instructions. They are all great
                            mentors at life &amp; career{" "}
                          </p>
                        </div>
                        <div className="author-info">
                          <div className="cite">
                            <h6 className="name">Sai Kumari Manthapuram</h6>
                            <span className="position">Course / Jagadguru</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shape shape-1 scene">
                    <span data-depth={3}>
                      <img
                        src="assets/images/shape-animation/shape-2.svg"
                        alt
                        className="svgInject"
                      />
                    </span>
                  </div>
                  <div className="shape shape-2 scene">
                    <span data-depth={-3}>
                      <img
                        src="assets/images/shape-animation/shape-3.png"
                        alt
                      />
                    </span>
                  </div>
                  <div className="shape shape-3 scene">
                    <span data-depth={4}>shape 3</span>
                  </div>
                  <div className="shape shape-4 scene">
                    <span data-depth={4}>
                      <img
                        src="assets/images/shape-animation/nwesletter-shape-2.png"
                        alt
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="teaching-content">
                  <div
                    className="section-title text-left mb-15"
                    data-aos="fade-up"
                  >
                    <span className="sub-title">Together expand and grow</span>
                    <h2 className="title">
                      Students Enjoy <span>Our Companionship</span> in Teaching.
                    </h2>
                  </div>
                  <p>
                    As learners, people can enjoy great companionship from
                    Rushividyabhyasam mentors and educators. We can help you
                    develop and grow at your best.
                  </p>
                  <a
                    href="/course"
                    className="btn btn-primary btn-hover-secondary max-mt-30"
                  >
                    View courses
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* Teaching Section End */}
      </div>
    </div>
  );
};

export default Aboutus;
