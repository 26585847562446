import React, { useState } from "react";
import Axios from "../utilities/Axios";
import { toast } from "react-toastify";
import logo from "../images/Spinner.gif";
import { useNavigate } from "react-router-dom";
const PaymentSucess = () => {
  const [data, setData] = React.useState(false);
  const [paydata, setPaydata] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  React.useEffect(() => {
    setLoading(true);
    const razorpay_order_id = window.localStorage.getItem("razorpay_order_id");
    const razorpay_payment_id = window.localStorage.getItem(
      "razorpay_payment_id"
    );
    const razorpay_signature =
      window.localStorage.getItem("razorpay_signature");
    const cid = window.localStorage.getItem("cid");
    const id = window.localStorage.getItem("id");
    const email_id = window.localStorage.getItem("mail");
    const user_name = window.localStorage.getItem("uname");
    // const id = window.localStorage.getItem("id");
    let price = window.localStorage.getItem("price");
    let currency_str = price?.substr(-3);

    let data = {
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
      id,
      email_id,
      payment_type: localStorage.getItem("payment_type"),
      currency: currency_str,
      user_name,
    };
    Axios.post("payments/payment_verify", data)
      .then((res) => {
        console.log(res.data);
        if (res.data.status === 200) {
          setData(true);
          setPaydata(res?.data.data[0]);
          // let price = window.localStorage.getItem("price");
          let amount = window.localStorage.getItem("amount");
          let uid = localStorage.getItem("uid");
          // let currency_str = price?.substr(-3);
          let email_id = localStorage.getItem("email_id");
          let obj = {
            user_id: uid,
            course_id: cid,
            email_id: email_id,
            amount: amount,
            payment_type: localStorage.getItem("payment_type"),
          };
          Axios.post("user_details/course_register", obj).then((res) => {
            // console.log(res?.data);

            if (res?.data?.status === 200) {
              // loaddata();
              // setDummy(false);
              setLoading(false);
              toast("Registered successfully", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
              // setTimeout(() => {
              //   toast(
              //     "Please go to Courses -> Instructors tab, to pick your teacher, to complete the registration.",
              //     {
              //       position: "bottom-right",
              //       autoClose: false,
              //       hideProgressBar: false,
              //       closeOnClick: true,
              //       pauseOnHover: true,
              //       draggable: true,
              //       progress: undefined,
              //       theme: "dark",
              //     }
              //   );
              // }, 1000);
            }
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
    setData(data);
  }, []);
  const navigate = useNavigate();
  const gotolink = () => {
    localStorage.setItem("instrcutertab", "true");
    localStorage.setItem("paycid", paydata?.course_id);
    navigate(`/coursedetails/${paydata?.course_id}`);
  };
  //console.log(paydata,"payyyyyyyy");
  if (loading) {
    return (
      <div className="loader">
        <img src={logo} alt="loader" />
      </div>
    );
  }
  return (
    <div>
      {data ? (
        <div className="pcard">
          <div className="card-body">
            <center>
              <i class="checkmark">✓</i>
              <h1>
                <b>Thank you for Donation</b> <br />
              </h1>
              <p>
                Course Name : <b>{paydata?.course_name}</b>
              </p>
              <p>
                Amount :{" "}
                <b>{`${paydata?.amount} ${window.localStorage
                  .getItem("price")
                  .substr(-3)}`}</b>
              </p>
              <p>
                Payment Id : <b>{paydata?.payment_id}</b>
              </p>
              <p>
                {" "}
                {paydata?.due_date != "" ? "Your next payment date :" : ""}{" "}
                <b>{paydata?.due_date}</b>{" "}
              </p>
              {/* <h5>Please go to course page and pick your teacher to complete registration process.</h5> */}

              {/* <div class="buy-c"><button class="btn btn-primary btn-hover-secondary" onClick={()=>gotolink()}>Click here for course page</button></div> */}
            </center>
          </div>
        </div>
      ) : (
        <div id="preloader" />
      )}
    </div>
  );
};
export default PaymentSucess;
